import api from "../../../api";
import { ErrorType } from "../../../utils/types";
import useFetch from "../../../utils/useFetch";


type FetchReturnType = {
  isLoading: boolean;
  isRefreshing: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: false | ErrorType;
  data: any;
refetch:any;
};

/**
 * @description fetch user types because we will need them on the user
 * list and when creating a user
 * @return {UserTypes} State changes across the fetch cycle
 */
export const getUserTypes = async (searchTerm: string) => {
  const res = await api.get(`/search/search-user-type-model/${searchTerm}`);
  return res.data;
};

/**
 * @description fetch user data, will be great when we have the user table
 * list and when creating a user
 * @return {User} State changes across the fetch cycle
 */
export const getSingleUser = async (id: string) => {
  const res = await api.get(`/auth/get-single-user/?usercode=${id}`);
  return res.data;
};

export const fetchUsers = async (page: number, rowsPerPage: number) => {
  const res = await api.get(
    `/auth/get-users?page=${page}&pagesize=${rowsPerPage}`
  );
  return res.data;
};

export const useFetchBookings = (
  page: number,
  pageSize: number,
  filter: string,
  startDate: string,
  endDate: string,
  slotDate: string,
): FetchReturnType =>
  useFetch(`paged-appointments-list?pageNumber=${page}&pageSize=${pageSize}&field=${filter}&startDate=${startDate}&endDate=${endDate}&slotDate=${slotDate}`, [
    'DashboardSchedules',
    `${page}`,
    `${pageSize}`,
    `${filter}`,
    `${slotDate}`,
  ]);

import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { filterBySearch } from "../../utils/filters";
import { DataTableColumn, ErrorType } from "../../utils/types";
import DataTable from "../components/Table3";
import usePostRequest from "../../utils/usePostRequest";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import useFetch from "../../utils/useFetch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GenericDialog from "../components/GenericDialog";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 16,
    },
  })
);

type DiagnosisProps = {
  Clientdata: [];
};

const Diagnosis: React.FC<DiagnosisProps> = ({ Clientdata }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [remarks, setRemarks] = useState<string>("");
  const [testID, setTestID] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const { handleSubmit, reset } = useForm();
  const { id }: any = useParams();
  // Fetch scope data
  const scope = useFetch(
    `api/Reports/get-Scope?NRC_Report_Id=${id}`,
    ["scope", id]
  );

  // Fetch comments data
  const { data, isLoading, isError, error } = useFetch(
    `api/Reports/get-doctorsRemarks?NRC_Report_Id=${id}&TestID=${testID}`,
    ["comments", id, testID]
  );

  // Fetch test records data
  const tests = useFetch(
    `api/Reports/TestRecords?NRC_Report_Id=${id}&TestID=${testID}`,
    ["tests", id, testID]
  );

  // Filtered data for scope
  const filteredScope = useMemo(() => {
    return scope.data ? filterBySearch(query, scope.data) : [];
  }, [query, scope.data]);

  const rows = filterBySearch(query, data || []);
  const rows2 = filterBySearch(query, tests.data || []);

  // Mutation for adding remarks
  const mutation = usePostRequest(
    "api/Reports/doctors-remarks",
    {
      nrC_Report_Id: id,
      createdBy: sessionStorage.getItem("UserID"),
      remarks,
      testID,
    },
    () => {
      queryClient.invalidateQueries(["comments"]);
      setOpen(false);
      setRemarks("");
    },
    (err: ErrorType) => console.error("Error posting remarks:", err)
  );

  const handleRemarksChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRemarks(event.target.value);
    },
    []
  );

  const handleAccordionChange = useCallback(
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setTestID(panel);
    },
    []
  );

  const handleOpenModal = useCallback((value: string) => {
    setOpen(true);
    setTestID(value);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };

  const columns: DataTableColumn[] = [
    { id: "remarks", label: "Remarks", minWidth: 650 },
  ];

  const columns2: DataTableColumn[] = [
    { id: "result", label: "RESULT", minWidth: 150 },
    { id: "normal", label: "NORMAL", minWidth: 150 },
    { id: "higher", label: "HIGH", minWidth: 150 },
    { id: "lower", label: "LOW", minWidth: 150 },
    { id: "total", label: "TOTAL", minWidth: 150 },
  ];

  return (
    <div className={classes.root}>
      <TextField
        size="small"
        placeholder="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />

      {scope.isLoading && <CircularProgress />}
      {scope.isError && (
        <Typography color="error">Failed to load scope data.</Typography>
      )}
      {filteredScope.map((value: any, index: number) => (
        <Accordion
          key={index}
          expanded={expanded === value.testID}
          onChange={handleAccordionChange(value.testID)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <Typography>{value.test}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Paper>
                    {isLoading ? (
                      <CircularProgress />
                    ) : isError ? (
                      <Typography color="error">
                        Failed to load comments.
                      </Typography>
                    ) : (
                      <>
                      <Box
                      m={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Button
                          color="primary"
                          variant="outlined"  
                          sx={ { borderRadius: 28 } }
                          onClick={e=>handleOpenModal(value.testID)}         
                        >
                      <AddIcon />
                    </Button>
                    </Box>
                      <DataTable
                        rows={rows.map((r, i) => ({ ...r, number: i + 1 }))}
                        columns={columns}
                      />
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper>
                    {tests.isLoading ? (
                      <CircularProgress />
                    ) : tests.isError ? (
                      <Typography color="error">
                        Failed to load test records.
                      </Typography>
                    ) : (
                      <DataTable
                        rows={rows2.map((r, i) => ({ ...r, number: i + 1 }))}
                        columns={columns2}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      <GenericDialog
        title="Add Doctor's Remarks"
        showDialog={open}
        width="sm"
        height={150}
        onCloseDialog={handleCloseModal}
        actions={[
          {
            label: "ADD REMARK",
            primary: true,
            onClick: handleSubmit(onSubmit),
          },
        ]}
        isLoading={mutation.isLoading}
      >
        <TextField
          size="small"
          label="Doctor's Remarks"
          variant="outlined"
          fullWidth
          multiline
          minRows={4}
          value={remarks}
          onChange={handleRemarksChange}
        />
      </GenericDialog>
    </div>
  );
};

export default Diagnosis;

import { Box, Snackbar, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import { CountryType } from "../components/CountryAutocomplete";
import DropdownOptions from "../components/DropdownOptions";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import SelectFromApi from "../components/SelectFromApi";
import { indexOf } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  EMAIL_ADDRESS_REGEX,
  NATIONAL_ID_REGEX,
  STAFF_ID_REGEX,
  USERNAME_REGEX,
} from "../../utils/regexes";
import { ErrorType } from "../../utils/types";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "./dashboard-logic/dashboardSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
  })
);

type Inputs = {
  email: string | undefined;
  name: string | undefined;
  staffNumber: string | undefined;
  idNumber: string | undefined;
  countryCode: string | undefined;
  userType?: string | undefined;
};

type CreateUserProps = {
  queryString: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setEditing: (value: boolean) => void;
  editing: boolean;
  setEditLoading?: boolean;
  setEditError?: boolean;
};

const options = ["Active", "Inactive"];

function CreateUser({
  queryString,
  open,
  setOpen,
  editing,
  setEditing,
  setEditLoading,
  setEditError,
}: CreateUserProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [values, setValues] = useState<Inputs>({
    email: "",
    name: "",
    staffNumber: "",
    countryCode: "",
    idNumber: "",
  });
  const [country, setCountry] = useState<string>("093");
  const [phone, setPhone] = useState<any>("");
  const [phoneError, setPhoneError] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState<string>("");
  const [openSnack, setOpenSnack] = useState(true);

  //const { selectedUser } = useAppSelector((state) => state.profile);
  // const [selectedStatus, setSelectedStatus] = useState(
  //   indexOf(options, selectedUser?.status || "Active")
  // );
  const [countryErr, setCountryError] = useState<false | ErrorType>(false);
  const [userTypeErr, setUserTypeErr] = useState<false | ErrorType>(false);

  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues: {
      ...values,
    },
  });
  const [postError, setPostError] = useState<false | ErrorType>(false);
  
  // useEffect(() => {
  //   if (editing && selectedUser && Object.keys(selectedUser).length) {
  //     setValues({
  //       email: selectedUser.email,
  //       name: selectedUser.name,
  //       staffNumber: selectedUser.staffNumber,
  //       idNumber: selectedUser.idNumber,
  //       countryCode: selectedUser.countryCode,
  //     });
  //     setPhone(selectedUser.phoneNumber);
  //     setSelectedUserType(selectedUser.userTypeCode);
  //     setSelectedStatus(indexOf(options, selectedUser.status));
  //   } else {
  //     setValues({
  //       email: "",
  //       name: "",
  //       staffNumber: "",
  //       idNumber: "",
  //       countryCode: "",
  //     });
  //     setPhone("");
  //     setSelectedUserType("");
  //   }
  // }, [editing]);

  const handleResetValues = () => {
    setValues({
      email: "",
      name: "",
      staffNumber: "",
      idNumber: "",
      countryCode: "",
    });
    setPhone("");
    setSelectedUserType("");
  };

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("user"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    setEditing(false);
    handleResetValues();
    queryClient.invalidateQueries(`${queryString}`);
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };

  const mutation = usePostRequest(
    "auth/register",
    {
      email: values.email,
      name: values.name,
      staffNumber: values.staffNumber,
      idNumber: values.idNumber,
      userType: selectedUserType,
      countryCode: country,
      status: "Active",
      phoneNumber: phone,
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    if (!country) {
      setCountryError({ message: "Customer country code is required" });
      return;
    }

    if (!selectedUserType) {
      setUserTypeErr({ message: "Select an appropriate user type" });
      return;
    }
    mutation.mutate();
  };

  const handleUserType = (newValue: any) => {
    setSelectedUserType(newValue?.code);
    setUserTypeErr(false);
  };

  const handleCountryChange = (newValue: CountryType | null) => {
    setCountry(newValue?.countryCode || "");
  };

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | number | symbol | any;
      value: unknown;
    }>
  ) => {
    setValues({ ...values, ...{ [event.target.name]: event.target.value } });
  };

  const handlePhoneChange = (event: any) => {
    setPhone(event);
  };

  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: `${editing ? "Edit" : "Create"} user`,
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
            {editing
              ? "User created Successfully"
              : "User updated successfully"}
          </Alert>
        </Snackbar>
      )}
      {editing && setEditError && (
        <Snackbar open={openSnack} onClose={() => setOpenSnack(false)}>
          <Alert onClose={handleClose} severity="error">
            {`Something wrong happened, could not edit user
            ${values.name}`}
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title={`${editing ? "Edit" : "Create"} Client`}
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
        <form>
          {mutation.isLoading || setEditLoading ? <LoadingSpinner /> : <></>}
          <div className={classes.grid}>
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            <TextField
              color="secondary"
              size="small"
              InputLabelProps={{ shrink: true }}
              className={classes.inputStyle}
              id="name"
              label="Client Name"
              variant="outlined"
              key="name"
              value={values?.name}
              fullWidth
              name="name"
              type="text"
              error={false}
              onChange={handleChange}
              inputRef={null}
            />
            {!editing && (
              <TextField
                color="secondary"
                size="small"
                className={classes.inputStyle}
                fullWidth
                autoComplete="off"
                id="staffNumber"
                label="Staff ID"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                name="staffNumber"
                type="text"
                onChange={handleChange}
                helperText={Error?.name}
                inputRef={null}
              />
            )}
            <TextField
              color="secondary"
              size="small"
              className={classes.inputStyle}
              id="idNumber"
              value={values?.idNumber}
              label="Id Number / Passport No."
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              name="idNumber"
              type="text"
              onChange={handleChange}
              helperText={Error?.name}
              inputRef={null}
            />
            {!editing && (
              <TextField
                color="secondary"
                size="small"
                className={classes.inputStyle}
                fullWidth
                autoComplete="off"
                id="email"
                label="Email"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                name="email"
                type="email"
                onChange={handleChange}
                helperText={Error?.name}
                inputRef={null}
              />
            )}
            <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="countries"
                primaryKey="countryCode"
                displayValueKey="countryName"
                onSelect={handleCountryChange}
                selected={country}
                error={countryErr}
                path="/setup/countries"
                label="Select user country"
                width="400px"
                alertWidth={500}
              />
            </Box>
            <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName="User Types"
                primaryKey="code"
                displayValueKey="description"
                onSelect={handleUserType}
                selected={selectedUserType}
                error={userTypeErr}
                path="/setup/get-user-types-listing"
                label="Select user type"
                width="100%"
                alertWidth={500}
              />
            </Box>

            <br />
            {editing && (
              <></>
              // <DropdownOptions
              //   options={options}
              //   editable
              //   selectedIndex={false}
              //   setSelectedIndex={false}
              // />
            )}
          </div>
        </form>
      </GenericDialog>
    </div>
  );
}

CreateUser.defaultProps = {
  setEditError: false,
  setEditLoading: false,
};
export default CreateUser;

import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { CreateOutlined } from "@mui/icons-material";
import { Box, Button, Link, Theme } from "@mui/material";
import FileSaver from "file-saver";
import * as XLSX from "xlsx"; // To create an Excel file
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table2";
import InlineSearchBar3 from "../components/Search/InlineSearchBar3";
import SelectFacility from "../components/selectFacility";
import useFetch from "../../utils/useFetch";
import { DataTableAction, DataTableColumn, DataTableRow } from "../../utils/types";
import { User } from "./dashboard-logic/dashboardSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
    },
    searchBox: {
      padding: theme.spacing(2, 0),
      width: "99%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    downloadButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};

const StockReportList: React.FC<Props> = ({ userTypeCode }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string>("");
  const [locationID, setLocationID] = useState<string>("F80A9A4B-9784-46E4-B6F3-D11F296256F9");
  const [updatedData, setUpdatedData] = useState<any[]>([]);
  const [searched, setSearched] = useState<string>("");
  const { data, isLoading, isSuccess   } = useFetch(`api/Reports/get-stock-level?LocationID=${locationID}&ProductID=${locationID}&pageNumber=${page}&pageSize=${rowsPerPage}`,["Stock",`${rowsPerPage},${page},${locationID}`],);
  const handleFacility = (option: any) => {
    setLocationID(option && (option as any).LocationID);
  };

  useEffect(() => {
    if (data) {
      setUpdatedData(
         data?.items || []
      );
    }
  }, [data, query]);

  const fetchAllData = async () => {
    let allData: any[] = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}api/Reports/get-stock-level?LocationID=${locationID}&ProductID=${locationID}&pageNumber=${1}&pageSize=${data?.totalCount}`);
      const result = await response.json();

      if (result.items) {
        allData = [...allData, ...result.items];
      }
      
      totalPages = result.totalPages;
      currentPage++;
    }

    return allData;
  };

  const handleDownload = async () => {
    const allData = await fetchAllData();

    const filteredData = allData.map((row: any) => ({
      location: row.location,
      item: row.item,
      systemStock: row.systemStock,
      derivedStock: row.derivedStock,
      physicalCount: row.physicalCount,
      sales: row.sales,
      stockReceived: row.stockReceived,
      transferedIn: row.transferedIn,
      transferedOut: row.transferedOut,
      cancelled: row.cancelled,
    }));
    const ws = XLSX.utils.json_to_sheet(filteredData); // Convert all data to a worksheet
    const wb = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(wb, ws, "StockReport"); // Append the worksheet to the workbook
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" }); // Write the workbook to a buffer
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" }); // Create a Blob from the buffer
    FileSaver.saveAs(blob, "StockReport.xlsx"); // Save the file
  };
  const columns: DataTableColumn[] = [
    { id: "location", label: "Facility Name", minWidth: 150 },
    { id: "item", label: "Product" },
    { id: "systemStock", label: "System Stock" },
    { id: "derivedStock", label: "Derived Stock" },
    { id: "physicalCount", label: "Physical Stock" },
    { id: "sales", label: "Sold", minWidth: 50 },
    { id: "stockReceived", label: "Stock Received", minWidth: 150 },
    { id: "transferedIn", label: "Transfered In", minWidth: 150 },
    { id: "transferedOut", label: "Transfered Out", minWidth: 150 },
    { id: "cancelled", label: "Cancelled", minWidth: 150 },
  ];
  const actions: DataTableAction[] = [
    {
      label: "Edit user",
      icon: () => <CreateOutlined />,
      onClick: (row: any) => {
        setUser(row);
        setEditing(true);
        setOpen(true);
      },
      permission: ['ACTIVE'].includes(data?.status?.toUpperCase())
        ? 'ACTION-NOT-ALLOWED'
        : "",
    },
  ];

  const rows: DataTableRow[] = isSuccess ? updatedData : [];
  return (
    <PageContent>
      <div className={classes.root}>
        {isLoading && <LoadingSpinner />}
        <div className={classes.formField}>
          <SelectFacility
            primaryKey="locationID"
            displayValueKey="name"
            onSelect={handleFacility}
            selected={locationID || ""}
            error={false}
            label="SELECT FACILITY"
            width="100%"
          />
        </div>
        {isSuccess && (
          <>
            <Box className={classes.searchBox}>
              <Box display="flex" alignItems="center">
                <InlineSearchBar3
                  onSearch={setQuery}
                  query={query}
                  setUpdatedData={setUpdatedData}
                  setSearched={setSearched}
                  updatedData={updatedData}
                  searched={searched}
                  setRowsPerPage={setRowsPerPage}
               
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownload}
                  className={classes.downloadButton}
                >
                  Download Stock Report
                </Button>
              </Box>
            </Box>
            <Table
              columns={columns}
              rows={rows.map((r, index) => ({ ...r, number: index + 1 }))}
              actions={actions}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              pageCount={data?.totalPages}
              rowColor=""
              totalRecords={rowsPerPage}
            />
          </>
        )}
      </div>
    </PageContent>
  );
};

StockReportList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default StockReportList;

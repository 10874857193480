/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
// import Table from "../components/Table";
import Table from "../components/Table4";
import React, { useCallback, useEffect, useState } from "react";
import {  useAppSelector } from "../../utils/hooks";
import { useNavigate } from 'react-router-dom';
import {
  DataTableAction,
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import {  User } from "./dashboard-logic/dashboardSlice";
import { Box, Button, Card, CircularProgress, Divider, Fade, IconButton, InputAdornment, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tab, Tabs, TextField, Theme,Grid,Dialog,DialogTitle } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EmptyList from "../components/EmptyList";
import MaterialTable from "@material-table/core";
import InlineSearchBar from "../components/Search/InlineSearchBar4";
import MoreVertIcon  from '@mui/icons-material/MoreVert';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import TableHeader from "../components/TableHeader";
import { Label } from "@mui/icons-material";
import ClearPayment from "../subscription/ClearPayment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AssignTicket from "./AssignTicket";
import ViewClientFeedback from "./ViewClientFeedback";
import CaptureClientFeedback from "./CaptureClientFeedback";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TableLink from "../components/TableLink";
import listOutline from '@iconify/icons-eva/list-outline';
import Iconify from './Inconify';
import { Icon, InlineIcon } from '@iconify/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TicketTracker from "./TicketTracker";
import CompleteTicket from "./CompleteTicket";
import InvoiceItems from "./InvoiceItems";
import  {useFetchTickets}  from "./dashboard-logic/useDashboardActions";
import format from 'date-fns/format';



const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    //root:{},
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  },


  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  unstyledButton: {
    textTransform: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },


}));

interface Invoice {
  status: string;
}
interface FilterParams {
  startDate: string;
  endDate: string;
  search: string;
}
interface Tabz {
  value: string;
  label: string;
  color: string;
  count: number | undefined;
}

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const TicketList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {
  const classes = useStyles();
  const INPUT_WIDTH = 360;
  const history = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
 const Token =sessionStorage.getItem('auth');
  const { selectedUser } = useAppSelector((state) => state.login);
 //const [data, SetnewData]=useState<string[]>([]);
  const [value, setValue] = React.useState<string>('00000');
  const [phone, setPhone] = React.useState<string>('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [ticketNo, setTicketNo] = React.useState<string>('');
  const [editing, setEditing] = useState(false);
  const [opePay, setOpenPay]=useState(false);
  const [OpenComplete, setOpenComplete]=useState(false);
    const [openInvoiceModalForm,setInvoiceOpenModalForm]= useState(false);
  const [openItemsModalForm,setItemsOpenModalForm]= useState(false);
  const [search, setSearch]=useState<string>('all');
  const [startDate, setStartDate]=useState<string>("");
  const [endDate, setEndDate]=useState<string>("");
  const [OpenCapture, setOpenCapture]=useState(false);
  const [OpenTicketTracker, setOpenTicketTracker]=useState(false);
  const[invoiceNumber,setInvoiceNumber]=useState<string>('');
  const [OpenView, setOpenView]=useState(false);
  const[updatedData, setUpdatedData] = useState([]);
  const [openModalForm,setOpenModalForm]= useState(false);
  const [searched, setSearched] = useState<string>("all");

  const [filterParams, setFilterParams] = useState<FilterParams>({
    startDate: '',
    endDate: '',
    search: 'all',
  });
  const getDefaultStartDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    return currentDate;
  };
  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };


  const [filterStartDate, setFilterStartDate] = useState<string>(
    formatDate(getDefaultStartDate())
    );
  const [filterEndDate, setFilterEndDate] = useState<string>(
    formatDate(new Date())
    );
    const[  isFilter,setIsFilter]=useState(false);
  
   
  // const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  // const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterName, setFilterName] = useState<string>('');
  const isFiltered = Boolean(filterStartDate || filterEndDate || filterName);
  const handleCloseSubscriptionForm = () => {
    setOpenModalForm(false);
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  
  const handleOpenItemsForm = (patNo:any) => {
    setItemsOpenModalForm(true);
    setInvoiceNumber(patNo);
  };
  const handleCloseItemsForm = () => {
    setItemsOpenModalForm(false);
  };
  const handleSubmit = () => {
    setValue(phone);
  };
  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;


      const onFilterStartDate = (date: any) => {
        setFilterStartDate(date);
        // Add logic to filter data based on the selected start date
      };
      const onFilterEndDate = (date: any) => {
        setFilterEndDate(date);
        // Add logic to filter data based on the selected end date
      };
      useEffect(() => {
        setFilterEndDate(
          formatDate(new Date())
          );
      }, [filterStartDate]);
    
      const onFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName(event.target.value);
        // Add logic to filter data based on the entered name
      };

    // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
       const {
            isLoading,
            isRefreshing,
            isSuccess,
            isError,
            data,
            refetch,
            error
           
          } = useFetchTickets(page,rowsPerPage,filterParams.search);
    // useFetch(`api/v1/checkups/patient/patient-medication-cycle?PageNumber=${page}&PageSize=${rowsPerPage}`, ["PatientVisit", `${page}`,`${rowsPerPage}`],
    // { enabled: !!value });

    const optionsData = data?.data || data || [] ;

    const [filterStatus, setFilterStatus] = useState<string>('all');

    let filteredData = [...optionsData];

    if (filterStatus !== 'all') {
      filteredData = filteredData.filter((item) => item.status === filterStatus);
    }
    const clearFilters = () => {
      setStartDate('');
      setEndDate('');
      setSearch('');
    };

    const handleDateClick = (date: Date | null) => {
      if (date) {
        const formattedDate = formatDate(date);
      }
    };

    const onResetFilter = () => {
      const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
      const end = format(new Date(filterEndDate), 'yyyy-MM-dd');
      
      setFilterStartDate(formatDate(getDefaultStartDate()));
      setFilterEndDate(formatDate(new Date()));
      setStartDate('');
      setEndDate('');
      setFilterName('');
      setSearch('');
      setIsFilter(false);

      setFilterParams({
        startDate: start,
        endDate: end,
        search: 'all',
      });

      refetch();
      // Add logic to reset filters and update your data accordingly
    };
    // const onFilter = () => {
    //   //const formattedDate = filterStartDate.toISOString().split('T')[0];,
    //   const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
    //   const end = format(new Date(filterEndDate), 'yyyy-MM-dd');
    //    setStartDate(start);
    //    setEndDate(end);
    //   setIsFilter(true);
    //   refetch();
    // };

    const onFilter = useCallback(() => {
      const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
      const end = format(new Date(filterEndDate), 'yyyy-MM-dd');
      
      setFilterParams({
        startDate: start,
        endDate: end,
        search: filterName || 'all',
      });
      
      setIsFilter(true);
    }, [filterStartDate, filterEndDate, filterName]);
  
    const handleFilterStatus = (event: React.ChangeEvent<{}>, newValue: string) => {
      setFilterStatus(newValue);
    };

    
    const statusCounts = data?.statusCounts || [];
    const totalCount = statusCounts.reduce((sum:any, item:any) => sum + (item.count || 0), 0);
    const TABS: Tabz[] = [
      { value: 'all', label: 'All', color: 'info', count: totalCount },
      ...statusCounts.map((statusCount: any) => {
        let color = 'info'; // Default color
        switch (statusCount.status) {
          case 'Open':
            color = 'warning';
            break;
            case 'Assigned':
              color = 'secondary';
              break;
              case 'Enroute':
                color = 'primary';
                break;
                case 'Arrived':
                  color = 'secondary';
                  break;
          case 'Processed':
            color = 'black';
            break;
          case 'In Progress':
            color = 'secondary';
            break;
          case 'Confirmed':
            color = 'success';
            break;
            case 'Closed':
              color = 'success';
              break;
          case 'Canceled':
            color = 'error';
            break;
            case 'Declined':
              color = 'error';
              break;
          default:
            color = 'info';
        }
        return {
          value: statusCount.status,
          label: statusCount.status,
          color,
          count: statusCount.count,
        };
      }),
    ];

// all Closed  Open New In Progress Due Declined
  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  const handleClickNewBtn = () => {
    history('/patient-details'); 
  };
  const handleClick=(patNo:any)=>{ 
    setisSubmitting(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ patientNumber: patNo })
    };
    fetch('https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/patient/patient-usage-report', requestOptions)
        .then(response => response.json())
        .then(data => setMessage(data));  
        setTimeout(() => setisSubmitting(false), 5000);    
  }

  
  const columns: DataTableColumn[] = [
    
    {
      label:"Ticket No",
      id: "ticketNumber",
     
    },
    {
      label:"Client Name",
      id: "fullName",
      render:(rowData:any)=>  <TableLink path={`/ticketdetails/${rowData.ticketNumber}`} >{rowData.fullName}</TableLink>,
     // minWidth: 100,
    },
    {
      label:"Phone Number",
      id: "phoneNumber",
    
    },
    // {
    //   title:<div className={classes.colHeader}>Email</div>,
    //   field: "email",
    //   type: "string" as const,
    //   //minWidth: 150,
    // },
    {
      label:"Visit Type",
      id: "visitType",
    
    },
    {
      label:"Intake Date",
      id: "intakeDate",
      
    },
  
    {
      label:"Assigned To",
      id: "assignedTo",
     
    },
    {
      label:"Department",
      id: "departmentName",
     
    },
    {
      label:"Insurance",
      id: "insurance",
    },

    {
      label:"Invoice Details",
      id: "paymentMethod",
      
      render: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleOpenItemsForm(value.invoiceID)} variant="outlined">
                <Icon icon={listOutline} />
         </Button>
         )
    },
   
    {
      label:"Status",
      id: "status",
      
    },
  
    {
      label:"Client Feedback",
      id:'ticketNumber',
      render: (rowData: any) => (
        <div>
        <Button
        variant="text"
        onClick={(event: any) => handleCaptureFeedback(event, rowData)}
      >
        Capture
      </Button> 
      <Button
        variant="text"
        onClick={(event: any) => handleViewFeedback(event, rowData)}
      >
        View 
      </Button>
      </div>
      )
    },
    
    
    {
      label:"Actions",
      id:"status",
      render: (rowData: any) => (
        <>
          {rowData.status !== "Closed" && rowData.status !== "Cancelled" && (
            <>
              <Button
                variant="text"
                startIcon={<AccountCircleIcon fontSize="small" />}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handlePayment(event, rowData)}
              >
                Allocate
              </Button>

              <Button
                variant="text"
                startIcon={<AccountCircleIcon fontSize="small" />}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleComplete(event, rowData)}
              >
                Complete
              </Button>
            </>
          )}
         
        </>
      ),
    },
  
    
  ];
const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?optionsData && optionsData
  : optionsData && optionsData
: [];

const handlePayment = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setOpenPay(true);
  setTicketNo(row.ticketNumber);
};
const handleComplete = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setOpenComplete(true);
  setTicketNo(row.ticketNumber);
};
const handleViewFeedback = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setOpenView(true);
  setTicketNo(row.ticketNumber);
};
const handleCaptureFeedback = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setOpenCapture(true);
  setTicketNo(row.ticketNumber);
};

const handleTicketMovement = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setOpenTicketTracker(true);
  setTicketNo(row.ticketNumber);
};
const handleOpenSubscriptionForm = (event: React.MouseEvent<HTMLElement>,row:any) => {
   setTicketNo(row.ticketNumber);
   setOpenModalForm(true);
};

const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const handleOpen = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setAnchorEl(event.currentTarget);
  setUser(row);
  setTicketNo(row.ticketNumber);
};
const handleClose = () => {
  setAnchorEl(null);
};
const open = Boolean(anchorEl);
const [slcRow, setSlcRow] = React.useState(null);
function handleMenuClick(event:any) {
  setAnchorEl(event.currentTarget);
  //setSlcRow(row);
}


  return (

    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        
      {isSuccess ? (

        <>
     
      <div style={{ display: 'flex' }}>

       <h2> TICKETING DASHBOARD PAGED</h2>

      </div>    
     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClickNewBtn}
            style={{ margin: 10, backgroundColor: 'green', color: 'white' }}
          >
            GENERATE TICKET
          </Button>
        </Box>
      </div>
    {/* start of search */}
    <Box sx={{ bgcolor: 'background.default',mt:2,mb:2 }}>
<Stack
spacing={2}
alignItems="center"
direction={{
 xs: 'column',
 md: 'row',
}}
sx={{ px: 2.5, py: 1 }}
>
<LocalizationProvider dateAdapter={AdapterDayjs}>

 <DatePicker 
 label="Start date"
 value={filterStartDate}
 onChange={onFilterStartDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
     />
     )}
 />

</LocalizationProvider>

<LocalizationProvider dateAdapter={AdapterDayjs}>
<DatePicker
 label="End date"
 value={filterEndDate}
 onChange={onFilterEndDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
   />
 )}
/>
</LocalizationProvider>
<TextField
 fullWidth
 value={filterName}
 onChange={onFilterName}
 placeholder="Search client name..."
 InputProps={{
   startAdornment: (
     <InputAdornment position="start">
       <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
     </InputAdornment>
   ),
 }}
/>
{isFiltered && (
<Button
color="primary" // Change to a suitable color for your search button
variant="contained" // Use 'contained' for a filled button style
sx={{ flexShrink: 0 }}
onClick={onFilter} // Change the click handler to your filtering logic
startIcon={<Iconify icon="eva:search-fill" />} // Replace with your search icon
>
Filter
</Button>
)}
{isFiltered && (
 <Button
   color="error"
   variant="contained" // Use 'contained' for a filled button style
   sx={{ flexShrink: 0 }}
   onClick={onResetFilter}
   startIcon={<Iconify icon="eva:trash-2-outline" />}
 >
   Clear
 </Button>
)}
</Stack>

</Box>
    {/* end of search */}
      {/* <Card> */}
      <Box sx={{ bgcolor: 'background.default' }}>
      <Tabs
  value={filterStatus}
  onChange={handleFilterStatus}
  sx={{
    px: 1,
    bgcolor: 'background.paper',
    width: '100%',
  }}
  variant="fullWidth"
>
  {TABS.map((tab) => (
    <Tab
      key={tab.value}
      value={tab.value}
      label={
        <div style={{ textAlign: 'center' ,fontSize:'1rem',lineHeight:'2rem'}}>
          <span>{tab.label}</span>
          <br />
          <span style={{ fontSize: '1.2rem',fontWeight:'bold' }}>
          {tab.count !== undefined ? tab.count.toLocaleString() : '0'}
          </span>
          <br/>
          <span style={{color:'#03fc13'}}>
            <ArrowUpwardIcon/>
          </span>
        </div>
      }
      icon={
        <Box component="span"></Box>
      }
      sx={{
        borderRadius: '10px',
        border: tab.value === "all"
          ? `1px solid gray`
          : tab.value === "Closed"
          ? `1px solid green`
          : tab.value === "Open"
          ? `1px solid blue`
          : tab.value === "New"
          ? `1px solid orange`
          : tab.value === "In Progress"
          ? `1px solid purple`
          : tab.value === "Due"
          ? `1px solid red`
          : tab.value === "Declined"
          ? `1px solid darkred`
          : '1px solid gray',
        marginLeft: 1,
        marginRight: 1,
        backgroundColor: 'transparent',
       
        color: 'black',
        padding: '1px 1px',

        '& .MuiTab-root': {
          borderRadius: '20px',
          backgroundColor: 'transparent',
          color: 'black',
          marginLeft: 1,
          marginRight: 1,
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      }}
    />

   



  ))}
</Tabs>
<Box className={classes.searchBox}>
          <Box display="flex" alignItems="center">
          <InlineSearchBar 
          onSearch={setQuery}
           query={query} 
           setUpdatedData={setUpdatedData}
            setSearched={setSearched}
             updatedData={updatedData}
              searched={searched} />
          </Box>
          </Box>
          <Table
            columns={columns}
            rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
            // actions={actions}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            totalRecords={data?.totalRecords}
            rowsPerPage={rowsPerPage}
            pageCount={data?.totalPages}
            rowColor={''}
          />

        
      </Box>

      {/* <Dialog fullWidth maxWidth="lg" open={openInvoiceModalForm} onClose={handleCloseInvoiceForm}>
        <DialogTitle>Invoice Payments</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <InvoicePayments
          onCancel={handleCloseInvoiceForm}
          setOpenModalForm={setInvoiceOpenModalForm}
          InvoiceNumber={invoiceNumber}
        />
      </Grid>
    </Grid>
    
      </Dialog> */}
      <Dialog fullWidth maxWidth="lg" open={openItemsModalForm} onClose={handleCloseItemsForm}>
        <DialogTitle>Invoice Details</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <InvoiceItems
          onCancel={handleCloseItemsForm}
          setOpenModalForm={setItemsOpenModalForm}
          InvoiceNumber={invoiceNumber}
        />
      </Grid>
    </Grid>
    
      </Dialog>


      {/* <Box sx={{ bgcolor: 'background.default',mt:2,mb:2 }}>
      <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 1 }}
    >
   <LocalizationProvider dateAdapter={AdapterDayjs}>

        <DatePicker 
        label="Start date"
        value={filterStartDate}
        onChange={onFilterStartDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
            />
            )}
        />

    </LocalizationProvider>
  
  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="End date"
        value={filterEndDate}
        onChange={onFilterEndDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />
  </LocalizationProvider>
      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder="Search client name..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
  {isFiltered && (
      <Button
      color="primary" // Change to a suitable color for your search button
      variant="contained" // Use 'contained' for a filled button style
      sx={{ flexShrink: 0 }}
      onClick={onResetFilter} // Change the click handler to your filtering logic
      startIcon={<Iconify icon="eva:search-fill" />} // Replace with your search icon
    >
      Filter
    </Button>
      )}
      {isFiltered && (
        <Button
          color="error"
          variant="contained" // Use 'contained' for a filled button style
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>

      </Box> */}
          <Divider />

          {/* <MaterialTable
            title=""
            columns={columns}
            data={dataFiltered}
            actions={[
              // {
              //   icon: () => <MoreVertIcon  />,
              //   tooltip: 'Actions',
              //   isFreeAction: false,
              //   onClick: (handleOpen)
              // }
           ]}
      
           // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            options={  {
              columnsButton:true,
              exportMenu: [{
                label: 'Export PDF',
                exportFunc: (cols, datas) => ExportPdf(cols, datas, 'SubscriptionReport')
              }, {
                label: 'Export CSV',
                exportFunc: (cols, datas) => ExportCsv(cols, datas, 'SubscriptionReport')
              }],
              exportAllData: true,
              sorting: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              }),
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },
             }}

             /> */}
        {/* </Card> */}
        </>
    
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
      <AssignTicket
        queryString="clients"
        open={opePay}
        setOpen={setOpenPay}
        ticketNo={ticketNo}
      />
       <CompleteTicket
        queryString="clients"
        open={OpenComplete}
        setOpen={setOpenComplete}
        ticketNo={ticketNo} orderID={""} PDepartmentID={0}      />
       <CaptureClientFeedback
        queryString="clients"
        open={OpenCapture}
        setOpen={setOpenCapture}
        ticketNo={ticketNo}
      />
      <TicketTracker
      
       open={OpenTicketTracker}
       setOpen={setOpenTicketTracker}
       TicketNo={ticketNo}
      
      />
      
       <ViewClientFeedback
       
        open={OpenView}
        setOpen={setOpenView}
        SurveyID={ticketNo}
        
      />
   
        
       
      {/* <Menu
         className={classes.root}
         id="fade-menu"
         anchorEl={anchorEl}
         transformOrigin={{
           vertical: 'top',
           horizontal: 'center',
         }}
         keepMounted
         open={open}
         onClose={handleClose}
         TransitionComponent={Fade}
       >
             {[
            <MenuItem onClick={handlePayment}
              classes={{ root: classes.menuItem }}
              className={
                // b.icon ? classes.menuItemWithIcon : 
                classes.menuItemNoIcon
              }
              key="Menu-Key"
            >
            <span className={classes.label}>Assign User</span>
            </MenuItem>
          ]}
      </Menu> */}
    </PageContent>

  );
};

TicketList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default TicketList;


const applyFilter = ({
  inputData,
  filterStatus,
}: {
  inputData: Invoice[];
  filterStatus: string;
}): Invoice[] => {
  if (filterStatus !== 'all') {
    inputData = inputData.filter((invoice) => invoice.status === filterStatus);
  }

  return inputData;
};
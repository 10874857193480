/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table5";
import React, { useCallback, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import {  useAppSelector } from "../../utils/hooks";
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from "react-query";
import {
  DataTableAction,
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {  User } from "./dashboard-logic/dashboardSlice";
import { Box, Button, Card, CircularProgress, Dialog, DialogTitle, Divider, Fade, Grid, IconButton, InputAdornment, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tab, Tabs, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import { Icon } from '@iconify/react';
import listOutline from '@iconify/icons-eva/list-outline';
import InlineSearchBar from "../components/Search/InlineSearchBar4";
import AssignOrder from "./AssignOrder";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Iconify from "../Ticketing/Inconify";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InvoicePayments from "./InvoicePayments";
import InvoiceItems from "./InvoiceItems";
import AssignDispatch from "./AssignDispatch";
import AssignAppointment from "./AssignAppointment";
import CompleteOrder from "./CompleteOrder";
import AssignBilling from "./AssignBilling";
import AssignProcurement from "./AssignProcurement";
import AssignFinance from "./AssignFinance";
import AssignPharmacy from "./AssignPharmacy";
import AssignDoctor from "./AssignDoctor";
import CancelOrder from "./CancelOrder";
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import  {useFetchOrders}  from "./dashboard-logic/useDashboardActions";
import format from 'date-fns/format';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      marginBottom:"20px",
    },
    //root:{},
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  },


  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  unstyledButton: {
    textTransform: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },


}));

interface Invoice {
  status: string;
}
interface FilterParams {
  startDate: string;
  endDate: string;
  search: string;
}
interface Tabz {
  value: string;
  label: string;
  color: string;
  count: number | undefined;
}

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];

type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const OrderList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {

  var permission =sessionStorage.getItem('role');
  var departmentRole =sessionStorage.getItem('departmentID');

  console.log("permission",permission);
  console.log("departmentRole",departmentRole);

  var departmentRole =sessionStorage.getItem('userDepartmentID');
  const classes = useStyles();
  const INPUT_WIDTH = 360;
  const history = useNavigate();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
 const Token =sessionStorage.getItem('auth');
  const { selectedUser } = useAppSelector((state) => state.login);
 //const [data, SetnewData]=useState<string[]>([]);
  const [value, setValue] = React.useState<string>('00000');
  const [phone, setPhone] = React.useState<string>('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [ticketNo, setTicketNo] = React.useState<string>('');
  const [orderNo, setOrderNo] = React.useState<string>('');
  const[latitude,setLatitude]=React.useState<number>(0);
  const[longitude,setLongitude]=React.useState<number>(0);
  const[departmentID,setDepartmentID]=React.useState<number>(0);
  const[locationName,setLocationName]=useState<string>('');
  const [editing, setEditing] = useState(false);
  const [opePay, setOpenPay]=useState(false);
  const[openDispatch,setOpenDispatch]=useState(false);
  const[openBilling,setOpenBilling]=useState(false);
  const[openProcurement,setOpenProcurement]=useState(false);
  const[openDoctor,setOpenDoctor]=useState(false);
  const[openCancel,setOpenCancel]=useState(false);
  const[openFinance,setOpenFinance]=useState(false);
  const[openPharmacy,setOpenPharmacy]=useState(false);
  const[openCompleteOrder,setOpenCompleteOrder]=useState(false);
  const[openRider,setOpenRider]=useState(false);
  const[openLabTech,setOpenLabTech]=useState(false);
  const [openInvoiceModalForm,setInvoiceOpenModalForm]= useState(false);
  const [openItemsModalForm,setItemsOpenModalForm]= useState(false);
  const[invoiceNumber,setInvoiceNumber]=useState<string>('');
  const[clientName,setClientName]=useState<string>('');
  const[clientPhoneNumber,setClientPhoneNumber]=useState<string>('');
  const[orderID,setorderID]=useState<string>('');
  const[updatedData, setUpdatedData] = useState([]);
  const [openModalForm,setOpenModalForm]= useState(false);
  const [searched, setSearched] = useState<string>("all");
  const [search, setSearch]=useState<string>('all');
  const [startDate, setStartDate]=useState<string>("");
  const [endDate, setEndDate]=useState<string>("");

    const [filterParams, setFilterParams] = useState<FilterParams>({
      startDate: '',
      endDate: '',
      search: 'all',
    });

  const getDefaultStartDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    return currentDate;
  };
  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };


  const [filterStartDate, setFilterStartDate] = useState<string>(
    formatDate(getDefaultStartDate())
    );
  const [filterEndDate, setFilterEndDate] = useState<string>(
    formatDate(new Date())
    );
    const[  isFilter,setIsFilter]=useState(false);
  
  const [filterName, setFilterName] = useState<string>('');
  const isFiltered = Boolean(filterStartDate || filterEndDate || filterName);
  const [comment, setcomment] = useState<string>("");
  const [orderInfo, setOrderInfo] = useState({ orderId: "", message: "" });

  const handleOpenInvoiceForm = (invoiceNumber:any) => {

    setInvoiceOpenModalForm(true);
    setInvoiceNumber(invoiceNumber);
  };

  const handleCloseInvoiceForm = () => {
    setInvoiceOpenModalForm(false);
  };
  const handleOpenItemsForm = (patNo:any) => {
    setItemsOpenModalForm(true);
    setInvoiceNumber(patNo);
  };

  const handleViewComment = (orderID:any) => {
    setItemsOpenModalForm(true);
    setOrderNo(orderID);
  };

  const handleCloseItemsForm = () => {
    setItemsOpenModalForm(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const handleSubmit = () => {
    setValue(phone);
  };
  const makeUrl = (defaultPath: string) =>
    query && !userTypeCode
      ? `/auth/system-user-fuzzy-search-user?searchterm=${query}`
      : defaultPath;


      const onFilterStartDate = (date: any) => {
        setFilterStartDate(date);
        // Add logic to filter data based on the selected start date
      };
      const onFilterEndDate = (date: any) => {
        setFilterEndDate(date);
        // Add logic to filter data based on the selected end date
      };
      useEffect(() => {
        setFilterEndDate(
          formatDate(new Date())
          );
      }, [filterStartDate]);
    
      const onFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName(event.target.value);
        // Add logic to filter data based on the entered name
      };

    // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
       const {
                 isLoading,
                 isRefreshing,
                 isSuccess,
                 isError,
                 data,
                 refetch,
                 error
                
               } = useFetchOrders(page,rowsPerPage,filterParams.search);
    // useFetch(`api/v1/checkups/patient/patient-medication-cycle?PageNumber=${page}&PageSize=${rowsPerPage}`, ["PatientVisit", `${page}`,`${rowsPerPage}`],
    // { enabled: !!value });

    const optionsData = data?.data || data || [] ;
   
    const [filterStatus, setFilterStatus] = useState<string>('all');

    let filteredData = [...optionsData];

    if (filterStatus !== 'all') {
      filteredData = filteredData.filter((item) => item.status === filterStatus);
    }
    
   
    const handleCompleteOrder = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenCompleteOrder(true);
    };
    const handleDispatchAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenDispatch(true);
    };
    const handleBillingAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setInvoiceNumber(row.invoiceNo);
      setOpenBilling(true);
      setClientPhoneNumber(row.clientName);
      setClientName(row.clientPhone);
    };


    const handleProcurementAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenProcurement(true);
    };
    const handleDoctorAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenDoctor(true);
    };

    const handleCancel = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenCancel(true);
    };
    const handleFinanceAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenFinance(true);
    };
    const handlePharmacyAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenPharmacy(true);
    };
    const handleLabAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenLabTech(true);
    };
    const handleRiderAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setOpenRider(true);
    };
    const handleNurseAssignment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setDepartmentID(row.departmentID);
      setLatitude(row.latitude);
      setLongitude(row.longitude);
      setLocationName(row.locationName);
      setcomment(row.comment);
      setOpenPay(true);
    };

    const handlePayment = (event: React.MouseEvent<HTMLElement>,row:any) => {
      setTicketNo(row.ticketNumber);
      setOrderNo(row.orderID);
      setLatitude(row.latitude);
      setLongitude(row.longitude);
      setLocationName(row.locationName);
      setOpenPay(true);
    };
    const handleViewFile = () => {
      const fileUrl = "api/FileUpload/Prescription"; 
      window.open(fileUrl, '_blank');
    };


    const onResetFilter = () => {
      const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
      const end = format(new Date(filterEndDate), 'yyyy-MM-dd');
      
      setFilterStartDate(formatDate(getDefaultStartDate()));
      setFilterEndDate(formatDate(new Date()));
      setStartDate('');
      setSearch('')
      setEndDate('');
      setFilterName('');
      setIsFilter(false);

      setFilterParams({
        startDate: start,
        endDate: end,
        search: 'all',
      });
      refetch();
      // Add logic to reset filters and update your data accordingly
    };

    const onFilter = useCallback(() => {
      const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
      const end = format(new Date(filterEndDate), 'yyyy-MM-dd');
      
      setFilterParams({
        startDate: start,
        endDate: end,
        search: filterName || 'all',
      });
      
      setIsFilter(true);
    }, [filterStartDate, filterEndDate, filterName]);
  
    const handleFilterStatus = (event: React.ChangeEvent<{}>, newValue: string) => {
      setFilterStatus(newValue);
    };
    
    const statusCounts = data?.statusCounts || [];
    const totalCount = statusCounts.reduce((sum:any, item:any) => sum + (item.count || 0), 0);
    const TABS: Tabz[] = [
      { value: 'all', label: 'All', color: 'info', count: totalCount },
      ...statusCounts.map((statusCount: any) => {
        let color = 'info'; // Default color
        switch (statusCount.status) {
          case 'Pending':
            color = 'warning';
            break;
            case 'Assigned':
              color = 'secondary';
              break;
              case 'Enroute':
                color = 'primary';
                break;
                case 'Arrived':
                  color = 'secondary';
                  break;
          case 'Processed':
            color = 'black';
            break;
          case 'Booked':
            color = 'secondary';
            break;
          case 'Confirmed':
            color = 'success';
            break;
            case 'Delivered':
              color = 'success';
              break;
          case 'Canceled':
            color = 'error';
            break;
            case 'Returned':
              color = 'error';
              break;
          default:
            color = 'info';
        }
        return {
          value: statusCount.status,
          label: statusCount.status,
          color,
          count: statusCount.count,
        };
      }),
    ];

// all Pending Confirmed Assigned Enroute Arrived Delivered Returned
  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  const handleClickNewBtn = () => {
    history('/new-ticket'); 
  };
  const handleClick=(patNo:any)=>{ 
    setisSubmitting(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ patientNumber: patNo })
    };
    fetch('https://dashboardapi.checkupsmed.com:1006/api/v1/checkups/patient/patient-usage-report', requestOptions)
        .then(response => response.json())
        .then(data => setMessage(data));  
        setTimeout(() => setisSubmitting(false), 5000);    
  }

  const columns: DataTableColumn[] = [
    {
      label:"Order",
      id: "orderID",
     
    },
    {
      label:"Ticket No",
      id: "ticketNumber",
     
    },
    {
      label:"Client Name",
      id: "clientName",
    
    },
    {
      label:"Phone Number",
      id: "clientPhone",
     
    },
    {
      label:"Assigned To",
      id: "assignedTo",
     
    },
    
    {
      label:"Invoice Details",
      id: "paymentMethod",
      
      format: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleOpenItemsForm(row.invoiceNo)} variant="outlined">
                <Icon icon={listOutline} />
         </Button>
         )
    },
    {
      label:"Intake Date",
      id: "intakeDate",
    },
    {
      label:"Target Time",
      id: "targetTime",
    },
    {
      label:"Expected TAT",
      id: "expectedTAT",
    },
  
    {
      label:"Charged Amount",
      id: "amount",
    },
    
    {
      label:"Comment",
      id: "comment",
    },
   
    {
      label:"Order Type",
      id: "orderType",
    },
    
    {
      label:"Status",
      id: "status", 
    },
    {
      label:"Action",
      id: "statusID",
      render: (rowData: any) => (
        
      <>
    {(departmentRole== "2" || permission==="SuperAdmin") ? (<>
    
      {rowData.statusID===0 &&(
      rowData.departmentID===2 &&(
        <Button
        variant="text"
        startIcon={<AccountCircleIcon fontSize="small" />}
        onClick={(event: any) => handleBillingAssignment(event, rowData)}
      >
        Assign_Billing
      </Button>
      )
           
      )}
      
      </>):(<></>)}
     {(departmentRole== "17" || permission==="SuperAdmin") ? (<>
          {rowData.statusID===1 &&(
           rowData.departmentID===17 &&(
              <Button
              variant="text"
              startIcon={<AccountCircleIcon fontSize="small" />}
              onClick={(event: any) => handleRiderAssignment(event, rowData)}
            >
              Assign_Rider
            </Button>
               )
               )}
           
             {rowData.statusID===1 &&(
                rowData.departmentID===17 &&(
                  <Button
                  variant="text"
                  startIcon={<CheckCircleTwoToneIcon fontSize="small" />}
                  onClick={(event: any) => handleCompleteOrder(event, rowData)}
                >
                  Complete_Order
                </Button>
           )   
           )}
           
              {rowData.statusID===1 &&(
              rowData.departmentID===17 &&(
                <Button
                variant="text"
                startIcon={<AccountCircleIcon fontSize="small" />}
                onClick={(event: any) => handleBillingAssignment(event, rowData)}
              >
                ReAssign_Billing
              </Button>
              )
                  
              )}
                    
               </>):(<></>)}
         {(departmentRole== "6" || permission==="SuperAdmin") ? (<>
           {rowData.statusID===0 &&(
             rowData.departmentID===6 &&(
              <Button
              variant="text"
              startIcon={<AccountCircleIcon fontSize="small" />}
              onClick={(event: any) => handleNurseAssignment(event, rowData)}
            >
              Assign_Nurse
            </Button>
                )
                )}</>):(<></>)}
  

  
     {(departmentRole== "22" || permission==="SuperAdmin") ? (<>
          
                
                {rowData.statusID===0 &&(
              rowData.departmentID===22 &&(
                <Button
                variant="text"
                startIcon={<AccountCircleIcon fontSize="small" />}
                onClick={(event: any) => handlePharmacyAssignment(event, rowData)}
              >
                Assign_Pharmacy
              </Button>
              )          
           )}
                </>):(<></>)} 
         {(departmentRole== "3" || permission==="SuperAdmin") ? (<>
        {rowData.statusID===0 &&(
          rowData.departmentID===3 &&(
            <Button
              variant="text"
              startIcon={<AccountCircleIcon fontSize="small" />}
              onClick={(event: any) => handleLabAssignment(event, rowData)}
            >
              Assign_LabTech
            </Button>
             )
             )}</>):(<></>)}
            {(departmentRole== "18" || permission==="SuperAdmin") ? (<>
             {rowData.statusID===1 &&(
                rowData.departmentID===18 &&(
                  <Button
                  variant="text"
                  startIcon={<CheckCircleTwoToneIcon fontSize="small" />}
                  onClick={(event: any) => handleCompleteOrder(event, rowData)}
                >
                  Complete_Order
                </Button>
           )   
           )}
  
             {rowData.statusID===1 &&(
              rowData.departmentID===18 &&(
                <Button
                variant="text"
                startIcon={<AccountCircleIcon fontSize="small" />}
                onClick={(event: any) => handleDispatchAssignment(event, rowData)}
              >
                Assign_Dispatch
              </Button>
              )          
           )}
           </>):(<></>)}
        </>

      ),
    },
  
  
    //End of Order Assignment
  
  
  
  
      {
        label:"Cancel Order",
        id: "statusID",
        
        render: (rowData: any) => (
          <>
            {(departmentRole === "2" || permission === "SuperAdmin") ? (
              <>
                {rowData.statusID === 0 && rowData.departmentID === 2 && (
                  <Button
                    variant="text"
                    startIcon={<HighlightOffTwoToneIcon  fontSize="small" />}
                    onClick={(event: any) => handleCancel(event, rowData)}
                  >
                    Cancel Order
                  </Button>
                )}
              </>
            ) : (<></> )}
            {(departmentRole === "18" || permission === "SuperAdmin") ? (
              <>
                {rowData.statusID === 1 && rowData.departmentID === 18 && (
                  <Button
                    variant="text"
                    startIcon={<HighlightOffTwoToneIcon  fontSize="small" />}
                    onClick={(event: any) => handleCancel(event, rowData)}
                  >
                    Cancel Order
                  </Button>
                )}
              </>
            ) : (<></> )}
  
         {(departmentRole === "17" || permission === "SuperAdmin") ? (
              <>
                {rowData.statusID === 1 && rowData.departmentID === 17 && (
                  <Button
                    variant="text"
                    startIcon={<HighlightOffTwoToneIcon fontSize="small" />}
                    onClick={(event: any) => handleCancel(event, rowData)}
                  >
                    Cancel Order
                  </Button>
                )}
              </>
            ) : (<></> )}
  
         {(departmentRole === "22" || permission === "SuperAdmin") ? (
              <>
                {rowData.statusID === 0 && rowData.departmentID === 22 && (
                  <Button
                    variant="text"
                    startIcon={<HighlightOffTwoToneIcon fontSize="small" />}
                    onClick={(event: any) => handleCancel(event, rowData)}
                  >
                    Cancel Order
                  </Button>
                )}
              </>
            ) : (<></> )}
  
  
         {(departmentRole === "4" || permission === "SuperAdmin") ? (
              <>
                {rowData.statusID === 0 && rowData.departmentID === 4 && (
                  <Button
                    variant="text"
                    startIcon={<HighlightOffTwoToneIcon  fontSize="small" />}
                    onClick={(event: any) => handleCancel(event, rowData)}
                  >
                    Cancel Order
                  </Button>
                )}
              </>
            ) : (<></> )}
             {(departmentRole === "23" || permission === "SuperAdmin") ? (
              <>
                {rowData.statusID === 0 && rowData.departmentID === 23 && (
                  <Button
                    variant="text"
                    startIcon={<HighlightOffTwoToneIcon fontSize="small" />}
                    onClick={(event: any) => handleCancel(event, rowData)}
                  >
                    Cancel Order
                  </Button>
                )}
              </>
            ) : (<></> )}
          </>
        ),
      },
  
    
    
  ];

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?optionsData && optionsData
  : optionsData && optionsData
: [];



const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const handleOpen = (event: React.MouseEvent<HTMLElement>,row:any) => {
  setAnchorEl(event.currentTarget);
  setUser(row);
  setTicketNo(row.ticketNumber);
};
const handleClose = () => {
  setAnchorEl(null);
};
const open = Boolean(anchorEl);
const [slcRow, setSlcRow] = React.useState(null);
function handleMenuClick(event:any) {
  setAnchorEl(event.currentTarget);
  //setSlcRow(row);
}

useEffect(() => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl("https://dashboardapi.checkupsmed.com:1006/orderHub")
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.on("ReceiveOrderUpdate", (orderId, message) => {
 
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    setOrderInfo({ orderId, message });
    const audio = new Audio('https://esquekenya.com/file/supermarket-pa-104750.mp3');
      audio.play();
      console.log("PLAY",orderId);
  });

  async function startConnection() {
    try {
      await connection.start();
    } catch (error) {
      console.error(error);
    }
  }

  startConnection();

  return () => {
    connection.stop()
      .then(() => {
      })
      .catch(error => {
        console.error(error);
      });
  };
}, []);

  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}

      {isSuccess ? (
        <>

        <div style={{ display: 'flex' }}>

<h2> ORDER DASHBOARD PAGED</h2>

</div>    
{/* start of Search */}

<Box sx={{ bgcolor: 'background.default',mt:2,mb:2 }}>
<Stack
spacing={2}
alignItems="center"
direction={{
 xs: 'column',
 md: 'row',
}}
sx={{ px: 2.5, py: 1 }}
>
<LocalizationProvider dateAdapter={AdapterDayjs}>

 <DatePicker 
 label="Start date"
 value={filterStartDate}
 onChange={onFilterStartDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
     />
     )}
 />

</LocalizationProvider>

<LocalizationProvider dateAdapter={AdapterDayjs}>
<DatePicker
 label="End date"
 value={filterEndDate}
 onChange={onFilterEndDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
   />
 )}
/>
</LocalizationProvider>
<TextField
 fullWidth
 value={filterName}
 onChange={onFilterName}
 placeholder="Search client name..."
 InputProps={{
   startAdornment: (
     <InputAdornment position="start">
       <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
     </InputAdornment>
   ),
 }}
/>
{isFiltered && (
<Button
color="primary" // Change to a suitable color for your search button
variant="contained" // Use 'contained' for a filled button style
sx={{ flexShrink: 0 }}
onClick={onFilter} // Change the click handler to your filtering logic
startIcon={<Iconify icon="eva:search-fill" />} // Replace with your search icon
>
Filter
</Button>
)}
{isFiltered && (
 <Button
   color="error"
   variant="contained" // Use 'contained' for a filled button style
   sx={{ flexShrink: 0 }}
   onClick={onResetFilter}
   startIcon={<Iconify icon="eva:trash-2-outline" />}
 >
   Clear
 </Button>
)}
</Stack>

</Box>

{/* End of Search */}
{/* <Card> */}
<Box sx={{ bgcolor: 'background.default' }}>
<Tabs
  value={filterStatus}
  onChange={handleFilterStatus}
  sx={{
    px: 1,
    bgcolor: 'background.paper',
    width: '100%',
  }}
  variant="fullWidth"
>
  {TABS.map((tab) => (
    <Tab
      key={tab.value}
      value={tab.value}
      label={
        <div style={{ textAlign: 'center' ,fontSize:'1rem',lineHeight:'2rem'}}>
          <span>{tab.label}</span>
          <br />
          <span style={{ fontSize: '1.2rem',fontWeight:'bold' }}>
            {tab.count !== undefined ? tab.count.toLocaleString() : '0'}
          </span>
          <br/>
          <span style={{color:'#03fc13'}}>
            <ArrowUpwardIcon/>
          </span>
        </div>
      }
      icon={
        <Box component="span"></Box>
      }
      sx={{
        borderRadius: '10px',
        border: tab.value === "all" ? `1px solid gray`
          : tab.value === "Delivered" ? `1px solid green`
          : tab.value === "Pending" ? `1px solid yellow`
          : tab.value === "Confirmed" ? `1px solid green`
          : tab.value === "Assigned" ? `1px solid blue`
          : tab.value === "Returned" ? `1px solid red`
          : tab.value === "Enroute" ? `1px solid orange`
          : '1px solid gray',
        marginLeft: 1,
        marginRight: 1,
        backgroundColor: 'transparent',
       
        color: 'black',
        padding: '1px 1px',

        '& .MuiTab-root': {
          borderRadius: '10px',
          backgroundColor: 'transparent',
        
          color: 'black',
          marginLeft: 1,
          marginRight: 1,
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      }}
    />
  ))}
</Tabs>
<Box className={classes.searchBox}>
          <Box display="flex" alignItems="center">
          <InlineSearchBar 
          onSearch={setQuery}
           query={query} 
           setUpdatedData={setUpdatedData}
            setSearched={setSearched}
             updatedData={updatedData}
              searched={searched} />
          </Box>
          </Box>
          <Table
            columns={columns}
            rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
            // actions={actions}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            totalRecords={data?.totalRecords}
            rowsPerPage={rowsPerPage}
            pageCount={data?.totalPages}
            rowColor={''}
          />


 
</Box>

   <Divider />

        </>
    
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
      <Dialog fullWidth maxWidth="lg" open={openInvoiceModalForm} onClose={handleCloseInvoiceForm}>
        <DialogTitle>Invoice Payments</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <InvoicePayments
          onCancel={handleCloseInvoiceForm}
          setOpenModalForm={setInvoiceOpenModalForm}
          InvoiceNumber={invoiceNumber}
        />
      </Grid>
    </Grid>
    
      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openItemsModalForm} onClose={handleCloseItemsForm}>
        <DialogTitle>Invoice Details</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <InvoiceItems
          onCancel={handleCloseItemsForm}
          setOpenModalForm={setItemsOpenModalForm}
          InvoiceNumber={invoiceNumber}
        />
      </Grid>
    </Grid>
    
      </Dialog>
      
      <AssignAppointment
        queryString="clients"
        open={opePay}
        setOpen={setOpenPay}
        ticketNo={ticketNo}
        orderNo={orderNo}
        clientlong={longitude}
        clientlat={latitude}
        clientLocationName={locationName}
        setClientLocationName={setLocationName}
        comment={comment}
        setcomment={setcomment}
        setClientLatitude={setLatitude}
        setClientLongitude={setLongitude}
      />
      <AssignOrder
        queryString="clients"
        open={openRider}
        setOpen={setOpenRider}
        ticketNo={ticketNo}
        orderNo={orderNo}
        clientlong={longitude}
        clientlat={latitude}
        clientLocationName={locationName}
        setClientLocationName={setLocationName}
        setClientLatitude={setLatitude}
        setClientLongitude={setLongitude}
      />
        <AssignDispatch
        queryString="clients"
        open={openDispatch}
        setOpen={setOpenDispatch}
        ticketNo={ticketNo}
        orderID={orderNo}
        PDepartmentID={departmentID}
      />
       <AssignBilling
        queryString="clients"
        open={openBilling}
        setOpen={setOpenBilling}
        ticketNo={ticketNo}
        orderID={orderNo}
        PDepartmentID={departmentID}
        InvoiceNumber={invoiceNumber}
        clientPhoneNumber={clientPhoneNumber}
        clientName={clientName}
      />
      <AssignProcurement
       queryString="clients"
       open={openProcurement}
       setOpen={setOpenProcurement}
       ticketNo={ticketNo}
       orderID={orderNo}
       PDepartmentID={departmentID}

      />

      <AssignDoctor
       queryString="clients"
       open={openDoctor}
       setOpen={setOpenDoctor}
       ticketNo={ticketNo}
       orderID={orderNo}
       PDepartmentID={departmentID}
      
      />

<CancelOrder
       queryString="clients"
       open={openCancel}
       setOpen={setOpenCancel}
       ticketNo={ticketNo}
       orderID={orderNo}
       PDepartmentID={departmentID}
      
      />

      <AssignFinance
       queryString="clients"
       open={openFinance}
       setOpen={setOpenFinance}
       ticketNo={ticketNo}
       orderID={orderNo}
       PDepartmentID={departmentID}
      />

      <AssignPharmacy
      queryString="clients"
      open={openPharmacy}
      setOpen={setOpenPharmacy}
      ticketNo={ticketNo}
      orderID={orderNo}
      PDepartmentID={departmentID}
      
      />

        <CompleteOrder
        queryString="clients"
        open={openCompleteOrder}
        setOpen={setOpenCompleteOrder}
        ticketNo={ticketNo}
        orderID={orderNo}
        PDepartmentID={departmentID}
      />
    </PageContent>
  );
};

OrderList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default OrderList;


const applyFilter = ({
  inputData,
  filterStatus,
}: {
  inputData: Invoice[];
  filterStatus: string;
}): Invoice[] => {
  if (filterStatus !== 'all') {
    inputData = inputData.filter((invoice) => invoice.status === filterStatus);
  }

  return inputData;
};
import loadable, { LoadableComponent } from "@loadable/component";
import {
  GroupOutlined,
  Dashboard,
  CalendarTodayOutlined,
  Assessment,
  GroupAdd,
  ChatBubble,
} from "@mui/icons-material";
import ShowChartTwoToneIcon from "@mui/icons-material/ShowChartTwoTone";
import SummarizeSharpIcon from "@mui/icons-material/SummarizeSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ViewListIcon from "@mui/icons-material/ViewList";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import InsuranceSetup from "../insurance-setup/InsuranceSetup";
import InsuranceScheme from "../insurance-scheme/insurance-scheme";
import Messaging from "../messaging/ChatPage";
import { USER_ROLES } from "../../constants";
// const CreatePackage = loadable(
//   () => import('services/products/packages/CreatePackage'),
// );
// import { PERMISSIONS } from '../auth/auth-logic/useCheckPermissions';

const DefaultLayout = loadable(
  () => import(/* webpackPrefetch: true */ "../components/DefaultLayout")
);
const Home = loadable(
  () => import(/* webpackPrefetch: true */ "../dashboard/DashboardList")
);
const SingleClients = loadable(
  () => import(/* webpackPrefetch: true */ "../dashboard/SingleClientsData")
);
const StockReportList = loadable(
  () => import(/* webpackPrefetch: true */ "../StockReport/StockReportList")
);
const UsersList = loadable(
  () => import(/* webpackPrefetch: true */ "../user-accounts/UsersList")
);
const VisitRecords = loadable(
  () => import(/* webpackPrefetch: true */ "../PatientUtilization/VisitRecords")
);
const VisitSummaryReport = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../PatientUtilization/SingleClientsData"
    )
);
const Ticketing = loadable(
  () => import(/* webpackPrefetch: true */ "../Ticketing/Ticketing")
);
const TicketingPaged = loadable(
  () => import(/* webpackPrefetch: true */ "../TicketingPaged/TicketingPaged")
);
const SingleTicketsData = loadable(
  () => import(/* webpackPrefetch: true */ "../Ticketing/SingleTicketsData")
);

const GenerateTicket = loadable(
  () => import(/* webpackPrefetch: true */ "../Ticketing/GenerateTicket")
);

const OrderList = loadable(
  () => import(/* webpackPrefetch: true */ "../Orders/OrderList")
);

const VisitSummaryList = loadable(
  () => import(/* webpackPrefetch: true */ "../VisitSummary/VisitSummaryList")
);

const Tests = loadable(
  () => import(/* webpackPrefetch: true */ "../Tests/TestList")
);
const PatientsRequest = loadable(
  () => import(/* webpackPrefetch: true */ "../subscription/PatientRequestList")
);
const ForgotPassword = loadable(
  () => import(/* webpackPrefetch: true */ "../auth/ForgotPassword")
);
const ResetPassword = loadable(
  () => import(/* webpackPrefetch: true */ "../auth/ResetPassword")
);

const Subscription = loadable(
  () => import(/* webpackPrefetch: true */ "../subscription/SubscriptionsList")
);
const SubscriptionAgent = loadable(
  () =>
    import(/* webpackPrefetch: true */ "../subscription/SubscriptionsAgents")
);

const SingleSubscription = loadable(
  () =>
    import(/* webpackPrefetch: true */ "../subscription/SingleSubscriptionData")
);
const BookingDashboard = loadable(
  () => import(/* webpackPrefetch: true */ "../packages/BookingDashboard")
);

const Patients = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../PatientReport/PatientsWellnessReport"
    )
);
const EnrollPatient = loadable(
  () =>
    import(/* webpackPrefetch: true */ "../enroll-patient/InsurancePatients")
);
const SingleFollowup = loadable(
  () => import(/* webpackPrefetch: true */ "../packages/packageDetails")
);
const Register = loadable(
  () => import(/* webpackPrefetch: true */ "../auth/sighup")
);
// const ConfirmOtp = loadable(
//   () => import(/* webpackPrefetch: true */ '../auth/ConfirmOtp'),
// );
const NoNavLayout = loadable(
  () => import(/* webpackPrefetch: true */ "../components/NoNavLayout")
);
/**
 * CONFIGURE ROUTE OBJECTS
 */
// export type Permission = string;

export type RouteType = {
  name: string;
  path: string;
  component: LoadableComponent<any> | any;
  layout: LoadableComponent<any> | any;
  showOnNav: boolean;
  icon: any;
  role?: string[];
};

const ROTES: RouteType[] = [
  {
    name: "get OTP",
    path: "/get-otp",
    component: "",
    layout: NoNavLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "Reset Password",
    path: "/forgot-password",
    component: ForgotPassword,
    layout: NoNavLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "New Password",
    path: "/reset-password/:id",
    component: ResetPassword,
    layout: NoNavLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "Register",
    path: "/register",
    component: Register,
    layout: NoNavLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "change user password",
    path: "/confirm-otp",
    component: "",
    layout: NoNavLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Home,
    layout: DefaultLayout,
    showOnNav: true,
    icon: Dashboard,
    role: [USER_ROLES.SUPERADMIN],
  },
  // {
  //   name: "Subscription",
  //   path: "/subscription",
  //   component: Subscription,
  //   layout: DefaultLayout,
  //   showOnNav: true,
  //   role: [
  //     USER_ROLES.SUPERADMIN,
  //     USER_ROLES.CUSTOMER_SERVICE,
  //     USER_ROLES.RECEPTIONIST,
  //     USER_ROLES.BILLING,
  //     USER_ROLES.CLINICIAN,
  //     USER_ROLES.ADMIN,
  //     USER_ROLES.NURSES,
  //     USER_ROLES.CLAIMS,
  //     USER_ROLES.PHARMACY,
  //     USER_ROLES.DISPATCH,
  //     USER_ROLES.FINANCE,
  //   ],
  //   icon: Inventory2Icon,
  // },
  {
    name: "Subscription",
    path: "/subscriptionPaged",
    component: Subscription,
    layout: DefaultLayout,
    showOnNav: true,
    role: [
      USER_ROLES.SUPERADMIN,
      USER_ROLES.CUSTOMER_SERVICE,
      USER_ROLES.RECEPTIONIST,
      USER_ROLES.BILLING,
      USER_ROLES.CLINICIAN,
      USER_ROLES.ADMIN,
      USER_ROLES.NURSES,
      USER_ROLES.CLAIMS,
      USER_ROLES.PHARMACY,
      USER_ROLES.DISPATCH,
      USER_ROLES.FINANCE,
    ],
    icon: Inventory2Icon,
  },
  {
    name: "Subscription Details",
    path: "/subscriptiondetails/:id",
    component: SingleSubscription,
    layout: DefaultLayout,
    showOnNav: false,
    icon: "",
  },
  /*  {
    name: "Nurse Allocation",
    path: "/NurseAllocation",
    component: NurseAllocation,
    layout: DefaultLayout,
    showOnNav:true,
    icon: PersonAddAltIcon,
  }, */
  // {
  //   name: "Tickets",
  //   path: "/ticketing",
  //   component: Ticketing,
  //   layout: DefaultLayout,
  //   showOnNav: true,
  //   role: [
  //     USER_ROLES.SUPERADMIN,
  //     USER_ROLES.CUSTOMER_SERVICE,
  //     USER_ROLES.RECEPTIONIST,
  //     USER_ROLES.CLINICIAN,
  //     USER_ROLES.NURSES,
  //   ],
  //   icon: ConfirmationNumberIcon,
  // },

  {
    name: "Tickets",
    path: "/ticketingPaged",
    component: TicketingPaged,
    layout: DefaultLayout,
    showOnNav: true,
    role: [
      USER_ROLES.SUPERADMIN,
      USER_ROLES.CUSTOMER_SERVICE,
      USER_ROLES.RECEPTIONIST,
      USER_ROLES.CLINICIAN,
      USER_ROLES.NURSES,
    ],
    icon: ConfirmationNumberIcon,
  },
  // {
  //   name: "Order",
  //   path: "/orders",
  //   component: OrderList,
  //   layout: DefaultLayout,
  //   showOnNav: true,
  //   role: [
  //     USER_ROLES.SUPERADMIN,
  //     USER_ROLES.CUSTOMER_SERVICE,
  //     USER_ROLES.BILLING,
  //     USER_ROLES.PHARMACY,
  //     USER_ROLES.DISPATCH,
  //     USER_ROLES.RECEPTIONIST,
  //   ],
  //   icon: ShoppingCartIcon,
  // },
  {
    name: "Order",
    path: "/ordersPaged",
    component: OrderList,
    layout: DefaultLayout,
    showOnNav: true,
    role: [
      USER_ROLES.SUPERADMIN,
      USER_ROLES.CUSTOMER_SERVICE,
      USER_ROLES.BILLING,
      USER_ROLES.PHARMACY,
      USER_ROLES.DISPATCH,
      USER_ROLES.RECEPTIONIST,
    ],
    icon: ShoppingCartIcon,
  },
  // {
  //   name: "Appointment",
  //   path: "/appointments",
  //   component: AppointmentList,
  //   layout: DefaultLayout,
  //   showOnNav:true,
  //   icon: EventIcon,
  // },

  {
    name: "Ticket Details",
    path: "/ticketdetails/:id",
    component: SingleTicketsData,
    layout: DefaultLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "Test Results",
    path: "/test-results",
    component: Tests,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN, USER_ROLES.CLINICIAN],
    icon: MedicalServicesIcon,
  },
  {
    name: "Patient Details",
    path: "/patient-details",
    component: GenerateTicket,
    layout: DefaultLayout,
    showOnNav: false,
    icon: MedicalServicesIcon,
  },
  {
    name: "Schedules",
    path: "/package",
    component: BookingDashboard,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN, 
      USER_ROLES.CLINICIAN,
      USER_ROLES.CUSTOMER_SERVICE],
    icon: CalendarTodayOutlined,
  },
  {
    name: "Package Details",
    path: "/followupdetails/:reminderdate",
    component: SingleFollowup,
    layout: DefaultLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "User Details",
    path: "/clientdetails?token=id",
    component: SingleClients,
    layout: DefaultLayout,
    showOnNav: false,
    icon: "",
  },
  // {
  //   name: "Agents",
  //   path: "/agent-accounts",
  //   component: Agents,
  //   layout: DefaultLayout,
  //   showOnNav: true,
  //   icon: GroupOutlined,
  // },
  {
    name: "Stock Reports",
    path: "/StockReportList",
    component: StockReportList,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN, USER_ROLES.FINANCE],
    icon: ShowChartTwoToneIcon,
  },

  {
    name: "Medical Report",
    path: "/patient-utilizations",
    component: VisitRecords,
    layout: DefaultLayout,
    showOnNav: true,
    role: [
      USER_ROLES.SUPERADMIN,
      USER_ROLES.CLINICIAN,
      USER_ROLES.PHARMACY,
      USER_ROLES.NURSES,
    ],
    icon: MedicalServicesIcon,
  },
  {
    name: "Summary Report",
    path: "/summaryreport/:id",
    component: VisitSummaryReport,
    layout: DefaultLayout,
    showOnNav: false,
    icon: "",
  },
  {
    name: "Mpesa Payments",
    path: "/payments",
    component: PatientsRequest,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN, USER_ROLES.FINANCE],
    icon: ViewListIcon,
  },
  {
    name: "Patients Report",
    path: "/patients",
    component: Patients,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN],
    icon: Assessment,
  },
  {
    name: "Visit Summary",
    path: "/VisitSummary",
    component: VisitSummaryList,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN, USER_ROLES.CLINICIAN, USER_ROLES.NURSES],
    icon: SummarizeSharpIcon,
  },
  {
    name: "Agent Dashboard",
    path: "/AgentPortal",
    component: SubscriptionAgent,
    layout: DefaultLayout,
    showOnNav: false,
    icon: Dashboard,
  },
  {
    name: "Users",
    path: "/user-accounts",
    component: UsersList,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN],
    icon: GroupOutlined,
  },
  {
    name: "Insurance Setup",
    path: "/insurance-setup",
    component: InsuranceSetup,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.SUPERADMIN],
    icon: SettingsIcon,
  },
  {
    name: "Insurance Setup",
    path: "/insurance-scheme",
    component: InsuranceScheme,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.INSURANCE_PROVIDER],
    icon: SettingsIcon,
  },
  {
    name: "Enroll Patient",
    path: "/enroll-list",
    component: EnrollPatient,
    layout: DefaultLayout,
    showOnNav: true,
    role: [USER_ROLES.INSURANCE_PROVIDER],
    icon: GroupAdd,
  },
  {
    name: "Messaging",
    path: "/messaging",
    component: Messaging,
    layout: DefaultLayout,
    showOnNav: true,
    role: [
      USER_ROLES.SUPERADMIN,
      USER_ROLES.INSURANCE_PROVIDER,
      USER_ROLES.CUSTOMER_SERVICE,
    ],
    icon: ChatBubble,
  },
];
export default ROTES;

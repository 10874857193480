import {  Box, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Snackbar, Table, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import GenericDialog from "../components/GenericDialog";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import usePostRequest from "../../utils/usePostRequest";
import { createUserSuccess } from "../agent-accounts/agent-accounts-logic/profileSlice";
import SelectFromApi from "../components/SelectFromApi";
import { ErrorType } from "../../utils/types";
import useFetch from "../../utils/useFetch";
import { filterBySearch } from "../../utils/filters";
import { AiOutlineSave, AiOutlineClose } from "react-icons/ai";

import { Dialog, DialogTitle, InputAdornment, Switch, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },

    inputStyle: {
      width: 400,
      marginBottom: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(1),
    },
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      zIndex: theme.zIndex.appBar - 1,
      outline: "none",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      //color: theme.palette.text.secondary,
      width: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: 'black !important',
      fontFamily: "ChronicaPro,sans-serif",
      fontSize:"16px",
      fontWeight: 400,
    },
    formCustomTitle: {
      fontWeight:700,
      color: "#449afe",
      fontSize:"1.3rem",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    CustomTitle: {
      fontWeight:700,
      color: "#022c5b",
      fontSize:"1.0rem! important",
      fontFamily: "ChronicaPro,sans-serif",
      width: 400,
    },
    inputLabel: {
      "&.focused": {
        color: "#449afe",
      },
      color: "#449afe",
    },
    errorText: {
      color: 'red',
      fontSize: 'small',
    },
  })
);


type FormData = {
  itemId: string; 
  item: string;
  quantity: number;
  nextRefillDate: string;
  isRefill: boolean;
};

type AssignBillingProps = {
  queryString: string;
  open: boolean;
  ticketNo: string;
  orderID: string;
  InvoiceNumber: string;
  PDepartmentID: number;
  clientName: string;
  clientPhoneNumber: string;
  // eslint-disable-next-line no-unused-vars
  setOpen: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
};

function AssignBilling({
  queryString,
  open,
  setOpen,
  ticketNo,
  orderID,
  InvoiceNumber,
  PDepartmentID,
  clientName,
  clientPhoneNumber
}: AssignBillingProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();


  const [openSnack, setOpenSnack] = useState(true);

  const [convertTicket, setConvertTicket] = useState<string>("");
  const { handleSubmit, reset,control } = useForm();
  const [postError, setPostError] = useState<false | ErrorType>(false);
  const [visitTypeErr, setVisitTypeErr] = useState<false | ErrorType>(false);
  // const [assignedTo, setassignedTo] = useState<string>("");
  const [department, setdepartment] = useState<string>("");
  const [assignedTo, setassignedToID] = useState<string>("");
  const [comment, setcomment] = useState<string>("");
  const [commentError,setCommentError]=useState<string>("");
  const [editMode, setEditMode] = useState(false);
  const [currentEdit, setCurrentEdit] = useState<any | null>(null);
  const [isRefillDate, setISRefillDate] = useState<string>("Yes");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [localData, setLocalData] = useState<FormData[]>([]); 
  // const [formData, setFormData] = useState<FormData>({
  //   item: "",
  //   quantity: 0,
  //   nextRefillDate: "",
  //   isRefill:true,
  // });
  const [editRowId, setEditRowId] = useState<string | null>(null); // Track row being edited
  const [formData, setFormData] = useState<FormData | null>(null); // Temp data for editing

  var UserID =sessionStorage.getItem('UserID');


  const drugs = useFetch(
    `get-invoice-drug-list?InvoiceNumber=${InvoiceNumber}`,
    ["drugs", InvoiceNumber]
  );

  const filteredDrugs = useMemo(() => {
    return drugs.data ? drugs.data : [];
  }, [drugs]);

  useEffect(() => {
    if (drugs.data) {
      const transformedData = drugs.data.map((drug:any) => ({
        ...drug,
        isRefill: true, // Ensure isRefill is true for all items
      }));
      setLocalData(transformedData);
    }
  }, [drugs?.data]);

  const onSuccess = ({ data }: any) => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("Orders"),
    });
    dispatch(createUserSuccess(data));
    setOpen(false);
    queryClient.invalidateQueries(`${queryString}`);
    
    // Clear the input fields
    setassignedToID("");
    setcomment("");
    setCommentError('');
  };
  const onFailure = (err: ErrorType) => {
    setPostError(err);
  };


  const handleChange = (event:any) => {
    const { name, value } = event.target;
    switch (name) {
      case 'comment':
        setcomment(value);
        setCommentError('');
        break;
        case 'convertTicket':
          setConvertTicket(value);
          break;
          case 'isRefillDate':
            setISRefillDate(value);
            break;
          case 'department':
            setdepartment(value);
            break;      
        default:
          break;
      }
    };
    // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //   const { name, value } = e.target;
    //   setFormData({ ...formData, [name]: value });
    // };
  
    const handleCloseEditForm=()=>{
      setOpenEditModal(false);
    }
    // const handleEditClick = (drug: any) => {
    //   setOpenEditModal(true);
    //   setEditMode(true);
    //   setCurrentEdit(drug);
    //   setFormData({
    //     item: drug.item,
    //     quantity: drug.quantity,
    //     nextRefillDate: drug.nextRefillDate,
    //     isRefill:true,
    //   });
    // };
  
    const handleEdit = (row: FormData) => {
      setEditRowId(row.itemId); // Set the row being edited
      setFormData({ ...row }); // Initialize form data with the row's data
    };
  
    // Save changes to the specific row
    const handleSave = (rowId: string) => {
      if (!formData) return; // Ensure formData exists
    
      const updatedData = localData.map((row) =>
        row.itemId === rowId ? { ...row, ...formData } : row
      );
    
      setLocalData(updatedData); // Update the local state
      setEditRowId(null); // Exit edit mode
      setFormData(null); // Clear temporary data
    };
    
    // Cancel editing
    const handleCancel = () => {
      setEditRowId(null);
      setFormData(null);
    };

    // const handleInputChangeTable = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   const { name, value } = e.target;
    //   setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    
    <Snackbar
  open={openSnack}
  onClose={() => setOpenSnack(false)}
  autoHideDuration={6000}
>
  <Alert severity="success">Changes saved successfully!</Alert>
</Snackbar>

    const handleAssigned = (newValue: any) => {
      setassignedToID(newValue?.userID);
    };
  const mutation = usePostRequest(
    "pharmacy-order-assignment",
    {
      ticketNumber: ticketNo,
      assignedTo,
      departmentID: 18,
      orderID:orderID,
      assignedBy: UserID,
      latitude:0,
      longitude: 0,
      locationName:"",
      status:1,
      notes:comment,
      department,
      refillData: isRefillDate==="Yes" ? localData : [],
      isRefill : isRefillDate,
      PatientName: clientName,
      PhoneNumber: clientPhoneNumber,
    },
    onSuccess,
    onFailure
  );

  const onSubmit = () => {
   
    // if (comment.trim() === '') {
    //   setCommentError('Comment is required.'); // Display a warning if the field is empty
    //   return;
    // }
    mutation.reset();
    mutation.mutate();
  };


  const handleClose = () => {
    mutation.reset();
    reset();
  };

  const actions = [
    {
      label: 'Submit Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <div>
      {mutation.isSuccess && (
        <Snackbar
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
              "Ticket Successfully Assigned"
          </Alert>
        </Snackbar>
      )}

      <GenericDialog
        title='Ticket Assignment'
        showDialog={open}
        onCloseDialog={() => {
          setOpen(false);
        }}
        actions={actions}
        isLoading={mutation.isLoading}
        width="sm"
        height={400}
        verticalAlign="flex-start"
      >
       
          {mutation.isLoading ? <LoadingSpinner /> : <></>}
          {/* <div className={classes.grid}> */}
            {postError ? (
              <Alert severity="error" className="error-container">
                {postError?.message}
              </Alert>
            ) : (
              <></>
            )}
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>    
            <Grid item xs={12} sm={12}>
         
          <FormControl component="fieldset">
            <RadioGroup
              name="convertTicket"
              value={convertTicket}
              onChange={handleChange}
              row
            >
                <FormControlLabel
                value="Accepted"
                control={<Radio />}
                label="Accept"
              />
              <FormControlLabel
                value="Declined"
                control={<Radio />}
               
                label="Decline"
              />
              <FormControlLabel
                value="Re-Assigned"
                control={<Radio />}
                label="Re-Assign"
              />
             
            </RadioGroup>
          </FormControl>
        </Grid>
        </Paper>

       {convertTicket && (
            <Paper className={classes.paper}>   

           {convertTicket==="Accepted" ? (
            <>
        <Paper className={classes.paper}>   
          <Grid item xs={12} sm={12}>
            
            <FormControl component="fieldset">
            <FormLabel>Is Chronic Client?</FormLabel>
              <RadioGroup
                name="isRefillDate"
                value={isRefillDate}
                onChange={handleChange}
                row
              >
                  <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          </Paper>



     {isRefillDate=="Yes" ?(
     <Grid item xs={12} sm={12}>

     <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="editable table">
    <TableHead>
      <TableRow>
        <TableCell align="left">Item</TableCell>
        <TableCell align="right">Quantity</TableCell>
        <TableCell align="right">Next Refill Date</TableCell>
        <TableCell align="right">IsRefill</TableCell>
        <TableCell align="right">Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {localData &&
        localData.map((row: any, index: number) => (
          <TableRow key={row.itemId}>
              {editRowId === row.itemId ? (
                <>
            <TableCell component="th" scope="row">
              {row.item}
            </TableCell>
            <TableCell align="right">
            {/* <input
                      type="number"
                      value={formData?.quantity || 0}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev!,
                          quantity: parseInt(e.target.value, 10),
                        }))
                      }
                    /> */}
                <TextField
                  name="quantity"
                  value={formData?.quantity || 0}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev!,
                      quantity: parseInt(e.target.value, 10),
                    }))
                  }
                  type="number"
                  variant="standard"
                />

            </TableCell>
            <TableCell align="right">
            <input
                type="date"
                value={
                  formData?.nextRefillDate
                    ? new Date(formData.nextRefillDate).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev!,
                    nextRefillDate: e.target.value, // Already in YYYY-MM-DD format from the input
                  }))
                }
              />
            </TableCell>
            <TableCell>
            <input
                      type="checkbox"
                      checked={formData?.isRefill || false}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev!,
                          isRefill: e.target.checked,
                        }))
                      }
                    />
            </TableCell>
            <TableCell align="right">
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <span
        onClick={() => handleSave(row.id)}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        title="Save"
      >
        <AiOutlineSave />
      </span>
      <span>|</span>
      <span
        onClick={handleCancel}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        title="Cancel"
      >
        <AiOutlineClose />
      </span>
    </div>
            </TableCell>
            </>):(
            <>
              <TableCell align="left">{row.item}</TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right"> {row.nextRefillDate}</TableCell>
              <TableCell align="right">{row.isRefill ? "Yes" : "No"} </TableCell>
              <TableCell align="right"> <button onClick={() => handleEdit(row)}>Edit</button> </TableCell>
            </>
            )}
          </TableRow>
        ))}
    </TableBody>
  </Table>
</TableContainer>
    
     </Grid>
     ):(<></>)}
        <Grid item xs={12} sm={12} marginTop={5}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
               /*  itemName={`userID-${department}`} */
                itemName={`userID-${department}-${ticketNo}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=18`}
                label="Select Staff Assign To"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>
         {/* ):(<></>)} */}

       </> 
           ):(<></>)}
          {convertTicket==="Re-Assigned" ? (
            <>
        <Grid item xs={12} sm={12}>
        <Box className={classes.autoComplete}>
              <SelectFromApi
                itemName={`userID-${PDepartmentID}-${ticketNo}`}
                primaryKey="userID"
                displayValueKey="fullName"
                onSelect={handleAssigned}
                selected={assignedTo || ""}
                error={visitTypeErr}
                path={`api/v1/checkups/tickets/users-bydepartment?DepartmentID=${PDepartmentID}`}
                label="Select Staff Assign To"
                width="100%"
                alertWidth={500}
              />
            </Box>
        </Grid>

       </> 
           ):(<></>)}
       {convertTicket &&(    
         <Grid item xs={12} sm={12}>
          <TextField
            name="comment"
            label="Comment"
            value={comment}
            onChange={handleChange}
            fullWidth
          />
         {commentError && <p style={{ color: 'red' }}>{commentError}</p>}
          </Grid>
       )}
          </Paper>
        )}
          </Grid>
          </Grid>

          {/* </div> */}
  
      </GenericDialog>
    </div>
  );
}

export default AssignBilling;

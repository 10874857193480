import React from 'react';
import { Box } from '@mui/material';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';

const locales = { 'en-US': require('date-fns/locale/en-US') };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type Event = {
  title: string;
  start: Date;
  end: Date;
  status?: string;
};

type Props = {
  events: Event[];
  setSlotDate: (date: string) => void;
  setViewMode: (mode: string) => void;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
};

const CalendarView: React.FC<Props> = ({ events,setSlotDate,setViewMode,setStartDate,setEndDate }) => {

    const handleSelectSlot = (slotInfo: any) => {
        const selectedDate = format(slotInfo.start, 'yyyy-MM-dd');
        setSlotDate(selectedDate);
        setStartDate("");
        setEndDate("");
        setViewMode("table");
      };

  return (
    <Box>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: '20px' }}
        selectable={true}
        onSelectEvent={handleSelectSlot}
      />
    </Box>
  );
};

export default CalendarView;

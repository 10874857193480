/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table5";
import React, { useCallback, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from "react-query";
import {
  DataTableAction,
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Button, Divider, InputAdornment, Stack, Switch, Tab, Tabs, TextField, Theme } from "@mui/material";
import EmptyList from "../components/EmptyList";
import InlineSearchBar from "../components/Search/InlineSearchBar4";
import Iconify from "../Ticketing/Inconify";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import  {useFetchBookings}  from "./package-logic/usePackageActions";
import BookingCalender from "./BookingCalender";
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import format from 'date-fns/format';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      marginBottom:"20px",
    },
    //root:{},
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colHeader: {
    "&:hover": {
      color: "red"
    }
  },


  menuItem: {
    minWidth: 200,
    margin: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemNoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItemWithIcon: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 800,
    fontSize: '0.95rem',
  },
  unstyledButton: {
    textTransform: 'none',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },


}));

interface Invoice {
  status: string;
}

interface Tabz {
  value: string;
  label: string;
  color: string;
  count: number | undefined;
}
interface FilterParams {
  startDate: string;
  endDate: string;
  search: string;
}
const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];
  
type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const OrderList: React.FC<Props> = ({
  roleName,
  roleId,
  userTypeCode,
}: Props) => {

  var permission =sessionStorage.getItem('role');
  var departmentRole =sessionStorage.getItem('departmentID');


  var departmentRole =sessionStorage.getItem('userDepartmentID');
  const classes = useStyles();
  const INPUT_WIDTH = 360;
  const history = useNavigate();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const[updatedData, setUpdatedData] = useState([]);
  const [searched, setSearched] = useState<string>("");
  const [search, setSearch]=useState<string>('all');
  const [startDate, setStartDate]=useState<string>("");
  const [endDate, setEndDate]=useState<string>("");
  const [slotDate, setSlotDate]=useState<string>("");
  //const [viewMode, setViewMode] = useState<'calendar' | 'table'>('table');
  const [viewMode, setViewMode] = useState<string>('calendar');
  const getDefaultStartDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    return currentDate;
  };
  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  const [filterStartDate, setFilterStartDate] = useState<string>(
    formatDate(getDefaultStartDate())
    );
  const [filterEndDate, setFilterEndDate] = useState<string>(
    formatDate(new Date())
    );
    const[  isFilter,setIsFilter]=useState(false);

  const [filterName, setFilterName] = useState<string>('');
  const isFiltered = Boolean(filterStartDate || filterEndDate || filterName);
  const [orderInfo, setOrderInfo] = useState({ orderId: "", message: "" });

  const [filterParams, setFilterParams] = useState<FilterParams>({
    startDate: '',
    endDate: '',
    search: 'all',
  });

      const onFilterStartDate = (date: any) => {
        setFilterStartDate(date);
      };
      const onFilterEndDate = (date: any) => {
        setFilterEndDate(date);

      };
      useEffect(() => {
        setFilterEndDate(
          formatDate(new Date())
          );
      }, [filterStartDate]);
    
      const onFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName(event.target.value);
        // Add logic to filter data based on the entered name
      };

    // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
       const {
                 isLoading,
                 isRefreshing,
                 isSuccess,
                 isError,
                 data,
                 refetch,
                 error
                
               // eslint-disable-next-line @typescript-eslint/no-use-before-define
               } = useFetchBookings(page,rowsPerPage,filterParams.search,filterParams.startDate,filterParams.endDate,slotDate);

    const optionsData = data?.data || data || [] ;
   
      const [filterStatus, setFilterStatus] = useState<string>('all');

      let filteredData = [...optionsData];

      if (filterStatus !== 'all') {
        filteredData = filteredData.filter((item) => item.status === filterStatus);
      }

      const statusCount: Record<string, number> = optionsData.reduce((acc: Record<string, number>, item: any) => {
        acc[item.status] = (acc[item.status] || 0) + 1;
        return acc;
      }, {});

    const calendarEvents = optionsData.map((item: any) => ({
      //title: `${item.clientName} - ${item.status}`,
      title: `${item.status} (${statusCount[item.status]})`,
      start: new Date(item.slotDate),
      end: new Date(item.slotDate),
      status: item.status,
    }));

    const clearFilters = () => {
      setStartDate('');
      setEndDate('');
      setSlotDate('');
      setSearch('');
    };

    const handleDateClick = (date: Date | null) => {
      if (date) {
        const formattedDate = formatDate(date);
        setSlotDate(formattedDate);
      }
    };

    const onResetFilter = () => {
      const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
      const end = format(new Date(filterEndDate), 'yyyy-MM-dd');

      setFilterStartDate(formatDate(getDefaultStartDate()));
      setFilterEndDate(formatDate(new Date()));
      setStartDate('');
      setEndDate('');
      setSlotDate('');
      setFilterName('');
      setIsFilter(false);

      setFilterParams({
        startDate: start,
        endDate: end,
        search: 'all',
      });

      refetch();
      // Add logic to reset filters and update your data accordingly
    };
    // const onFilter = () => {
    //   //const formattedDate = filterStartDate.toISOString().split('T')[0];,
    //   const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
    //   const end = format(new Date(filterEndDate), 'yyyy-MM-dd');
    //    setStartDate(start);
    //    setEndDate(end);
    //   setIsFilter(true);
    //   refetch();
    // };


    const onFilter = useCallback(() => {
      const start = format(new Date(filterStartDate), 'yyyy-MM-dd');
      const end = format(new Date(filterEndDate), 'yyyy-MM-dd');
      
      setFilterParams({
        startDate: start,
        endDate: end,
        search: filterName || 'all',
      });
      
      setIsFilter(true);
    }, [filterStartDate, filterEndDate, filterName]);
  
    const handleFilterStatus = (event: React.ChangeEvent<{}>, newValue: string) => {
      console.log("newValue",newValue);
      setFilterStatus(newValue);
    };
    
    const statusCounts = data?.statusCounts || [];
   const totalCount = statusCounts.reduce((sum:any, item:any) => sum + (item.count || 0), 0);
  
    const TABS: Tabz[] = [
      { value: 'all', label: 'All', color: 'info', count: totalCount },
      ...statusCounts.map((statusCount: any) => {
        let color = 'info'; // Default color
        switch (statusCount.status) {
          case 'Pending':
            color = 'warning';
            break;
          case 'Assigned':
            color = 'primary';
            break;
          case 'Booked':
            color = 'secondary';
            break;
          case 'Confirmed':
            color = 'success';
            break;
          case 'Canceled':
            color = 'error';
            break;
          default:
            color = 'info';
        }
        return {
          value: statusCount.status,
          label: statusCount.status,
          color,
          count: statusCount.count,
        };
      }),
    ];
// all Pending Confirmed Assigned Enroute Arrived Delivered Returned
  const [message, setMessage]=useState<string>("");
  const [isSubmitting,setisSubmitting]=useState<boolean>(false);
  const handleClickNewBtn = () => {
    history('/new-ticket'); 
  };

  const columns: DataTableColumn[] = [
    {
      label:"BookingID",
      id: "appointmentId",
     
    },
    {
      label:"Client Name",
      id: "clientName",
    
    },
    {
      label:"Phone Number",
      id: "phoneNumber",
     
    },
    {
      label:"Appointment Type",
      id: "appointmentType",
     
    },
  
    {
      label:"Appointment Date",
      id: "slotDate",
    },
    {
      label:"Slot Time",
      id: "slotTime",
    },
    {
      label:"Slot Duration",
      id: "slotDuration",
    },
    
    {
      label:"Comment",
      id: "comment",
    },
    {
      label:"Status",
      id: "status", 
    },
    {
      label:"Date Requested",
      id: "createdAt",
    },
    
    //End of Order Assignment
    
  ];

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?filteredData && filteredData
  : filteredData && filteredData
: [];

useEffect(() => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl("https://dashboardapi.checkupsmed.com:1006/orderHub")
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.on("ReceiveOrderUpdate", (orderId, message) => {
 
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("DashboardSchedules"),
    });
    setOrderInfo({ orderId, message });
    const audio = new Audio('https://esquekenya.com/file/supermarket-pa-104750.mp3');
     // audio.play();

  });

  async function startConnection() {
    try {
      await connection.start();
    } catch (error) {
      console.error(error);
    }
  }

  startConnection();
  return () => {
    connection.stop()
      .then(() => {
      })
      .catch(error => {
        console.error(error);
      });
  };
}, []);

  return (
    <PageContent>
      <div className={classes.root}>

        {isLoading ? <LoadingSpinner /> : <></>}
        
      {isSuccess ? (
        <>
        <div style={{ display: 'flex' }}>

<h2> ORDER DASHBOARD PAGED</h2>

</div>    
{/* start of Search */}

<Box sx={{ bgcolor: 'background.default',mt:2,mb:2 }}>
<Stack
spacing={2}
alignItems="center"
direction={{
 xs: 'column',
 md: 'row',
}}
sx={{ px: 2.5, py: 1 }}
>
<LocalizationProvider dateAdapter={AdapterDayjs}>

 <DatePicker 
 label="Start date"
 value={filterStartDate}
 onChange={onFilterStartDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
     />
     )}
 />

</LocalizationProvider>

<LocalizationProvider dateAdapter={AdapterDayjs}>
<DatePicker
 label="End date"
 value={filterEndDate}
 onChange={onFilterEndDate}
 renderInput={(params) => (
   <TextField
     {...params}
     fullWidth
     sx={{
       maxWidth: { md: INPUT_WIDTH },
     }}
   />
 )}
/>
</LocalizationProvider>
<TextField
 fullWidth
 value={filterName}
 onChange={onFilterName}
 placeholder="Search client name..."
 InputProps={{
   startAdornment: (
     <InputAdornment position="start">
       <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
     </InputAdornment>
   ),
 }}
/>
{isFiltered && (
 <Button
 color="primary" 
 variant="contained" 
 sx={{ flexShrink: 0 }}
 onClick={onFilter}
 disabled={isLoading} // Add disabled state while loading
 startIcon={<Iconify icon="eva:search-fill" />} 
>
 {isLoading ? 'Filtering...' : 'Filter'}
</Button>
)}
{isFiltered && (
 <Button
   color="error"
   variant="contained" 
   sx={{ flexShrink: 0 }}
   onClick={onResetFilter}
   startIcon={<Iconify icon="eva:trash-2-outline" />}
 >
   Clear
 </Button>
)}
</Stack>

</Box>

{/* End of Search */}
{/* <Card> */}
<Box sx={{ bgcolor: 'background.default' }}>
<Tabs
  value={filterStatus}
  onChange={handleFilterStatus}
  sx={{
    px: 1,
    bgcolor: 'background.paper',
    width: '100%',
  }}
  variant="fullWidth"
>
  {TABS.map((tab) => (
    <Tab
      key={tab.value}
      value={tab.value}
      label={
        <div style={{ textAlign: 'center' ,fontSize:'1rem',lineHeight:'2rem'}}>
          <span>{tab.label}</span>
          <br />
          <span style={{ fontSize: '1.2rem',fontWeight:'bold' }}>
            {tab.count !== undefined ? tab.count.toLocaleString() : '0'}
          </span>
          <br/>
          <span style={{color:'#03fc13'}}>
            <ArrowUpwardIcon/>
          </span>
        </div>
      }
      icon={
        <Box component="span"></Box>
      }
      sx={{
        borderRadius: '10px',
        border: tab.value === "all" ? `1px solid gray`
          : tab.value === "Delivered" ? `1px solid green`
          : tab.value === "Pending" ? `1px solid yellow`
          : tab.value === "Confirmed" ? `1px solid green`
          : tab.value === "Assigned" ? `1px solid blue`
          : tab.value === "Returned" ? `1px solid red`
          : tab.value === "Enroute" ? `1px solid orange`
          : '1px solid gray',
        marginLeft: 1,
        marginRight: 1,
        backgroundColor: 'transparent',
       
        color: 'black',
        padding: '1px 1px',

        '& .MuiTab-root': {
          borderRadius: '10px',
          backgroundColor: 'transparent',
        
          color: 'black',
          marginLeft: 1,
          marginRight: 1,
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      }}
    />
  ))}
</Tabs>
<Box className={classes.searchBox}>
          <Box display="flex" alignItems="center">
          <InlineSearchBar 
          onSearch={setQuery}
           query={query} 
           setUpdatedData={setUpdatedData}
            setSearched={setSearched}
             updatedData={updatedData}
              searched={searched} />
            <Switch
              checked={viewMode === 'calendar'}
              onChange={() => {
                if (viewMode === 'calendar') {
                  setViewMode('table');
                } else {
                  clearFilters();
                  setViewMode('calendar');
                }
              }}
              color="primary"
              inputProps={{ 'aria-label': 'View mode toggle' }}
              />
             <span>{viewMode === 'calendar' ? 'Calendar View' : 'Table View'}</span>
          </Box>
          </Box>
          {viewMode === 'calendar' ? (
               <BookingCalender events={calendarEvents} setStartDate={setFilterStartDate} setEndDate={setFilterEndDate}
                setSlotDate={setSlotDate} setViewMode={setViewMode}
                />
      
            ) : (
              <Table
              columns={columns}
              rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
              // actions={actions}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              totalRecords={data?.totalRecords}
              rowsPerPage={rowsPerPage}
              pageCount={data?.totalPages}
              rowColor={''}
            />
            )}
        
</Box>

   <Divider />
   
        </>
    
      ) : (<EmptyList message="You may want to refine your filters" />)}
      </div>
      
    </PageContent>
  );
};

OrderList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default OrderList;


const applyFilter = ({
  inputData,
  filterStatus,
}: {
  inputData: Invoice[];
  filterStatus: string;
}): Invoice[] => {
  if (filterStatus !== 'all') {
    inputData = inputData.filter((invoice) => invoice.status === filterStatus);
  }

  return inputData;
};
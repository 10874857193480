import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  Grid, 
  Typography, 
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  Legend 
} from 'recharts';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 400,
  marginTop: theme.spacing(2),
}));

// Types
interface DashboardStats {
  totalPatients: number;
  totalVisits: number;
  totalSales: number;
  subscriptionEnrollments: number;
}

interface VisitType {
  type: string;
  count: number;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#666'];

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const [timeRange, setTimeRange] = useState<string>('MTD');
  const [stats] = useState<DashboardStats>({
    totalPatients: 12500,
    totalVisits: 45000,
    totalSales: 850000,
    subscriptionEnrollments: 2300
  });

  const visitTypes: VisitType[] = [
    { type: 'Pharmacy', count: 450 },
    { type: 'Wellness', count: 320 },
    { type: 'Tel-consultations', count: 280 },
    { type: 'GCC', count: 200 },
    { type: 'MLS', count: 180 },
    { type: 'Consultations', count: 350 },
    { type: 'Nurse Visit', count: 150 },
    { type: 'Walkin Laboratory', count: 220 },
    { type: 'Home Sample Collection', count: 130 }
  ];

  const StatCard: React.FC<{ title: string; value: number; trend?: number }> = ({ title, value, trend }) => (
    <StyledCard>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" component="div">
          {value.toLocaleString()}
        </Typography>
        {trend !== undefined && (
          <Box display="flex" alignItems="center" mt={1}>
            {trend >= 0 ? (
              <TrendingUpIcon color="success" />
            ) : (
              <TrendingDownIcon color="error" />
            )}
            <Typography 
              variant="body2" 
              color={trend >= 0 ? "success.main" : "error.main"}
              sx={{ ml: 1 }}
            >
              {trend >= 0 ? '+' : ''}{trend}% vs last period
            </Typography>
          </Box>
        )}
      </CardContent>
    </StyledCard>
  );

  const monthlyData = [
    { month: 'Jan', visits: 4000, sales: 2400 },
    { month: 'Feb', visits: 3000, sales: 1398 },
    { month: 'Mar', visits: 2000, sales: 9800 },
    { month: 'Apr', visits: 2780, sales: 3908 },
    { month: 'May', visits: 1890, sales: 4800 },
    { month: 'Jun', visits: 2390, sales: 3800 }
  ];

  const dailyData = [
    { date: 'Mon', patients: 4000, visits: 2400, refills: 2400 },
    { date: 'Tue', patients: 3000, visits: 1398, refills: 2210 },
    { date: 'Wed', patients: 2000, visits: 9800, refills: 2290 },
    { date: 'Thu', patients: 2780, visits: 3908, refills: 2000 },
    { date: 'Fri', patients: 1890, visits: 4800, refills: 2181 }
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" component="h1">
          Hospital Dashboard
        </Typography>
        <Typography
          variant="h6"
          component="p"
          style={{ color: "red", fontWeight: "bold", marginTop: "8px" }}
        >
         COMING SOON !!!! CURRENTLY ON DUMMY DATA
        </Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Time Range</InputLabel>
          <Select
            value={timeRange}
            label="Time Range"
            onChange={(e) => setTimeRange(e.target.value)}
          >
            <MenuItem value="MTD">Month to Date</MenuItem>
            <MenuItem value="YTD">Year to Date</MenuItem>
            <MenuItem value="DAILY">Daily</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        {/* Stats Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Total Patients" value={stats.totalPatients} trend={5.2} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Total Visits" value={stats.totalVisits} trend={-2.1} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Total Sales" value={stats.totalSales} trend={8.4} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard title="Subscriptions" value={stats.subscriptionEnrollments} trend={12.3} />
        </Grid>

        {/* Visit Types Distribution */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Visit Types Distribution</Typography>
              <ChartContainer>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={visitTypes}
                      dataKey="count"
                      nameKey="type"
                      cx="50%"
                      cy="50%"
                      outerRadius={120}
                      label
                    >
                      {visitTypes.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </ChartContainer>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Monthly Trends */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Monthly Trends</Typography>
              <ChartContainer>
                <ResponsiveContainer>
                  <LineChart data={monthlyData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="visits" stroke="#8884d8" name="Visits" />
                    <Line type="monotone" dataKey="sales" stroke="#82ca9d" name="Sales" />
                  </LineChart>
                </ResponsiveContainer>
              </ChartContainer>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Daily Statistics */}
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Daily Statistics</Typography>
              <ChartContainer>
                <ResponsiveContainer>
                  <BarChart data={dailyData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="patients" fill="#8884d8" name="Patients" />
                    <Bar dataKey="visits" fill="#82ca9d" name="Visits" />
                    <Bar dataKey="refills" fill="#ffc658" name="Refills" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
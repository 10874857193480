/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles } from "@mui/styles";
import { CreateOutlined } from "@mui/icons-material";
import LoadingSpinner from "../components/LoadingSpinner";
import PageContent from "../components/pageContent";
import Table from "../components/Table2";
import TableLink from "../components/TableLink";
import { Fade,Autocomplete, Avatar, Box, Button, Dialog, DialogTitle, Link, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, TextField, Theme, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DataTableAction,
  DataTableColumn,
  DataTableRow,
} from "../../utils/types";
import useFetch from "../../utils/useFetch";
import CreateUser from "./CreateItem";
import { User } from "./dashboard-logic/dashboardSlice";
import moment from "moment";
import { Icon, InlineIcon } from '@iconify/react';
import listOutline from '@iconify/icons-eva/list-outline';
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import SelectTypeOfService from "../components/selectTypeOfService";
import InlineSearchBar from "../components/Search/InlineSearchBar2";
import MaterialTable from "@material-table/core";
import  {useFetchSubscribers}  from "./dashboard-logic/useDashboardActions";
import ClearPayments from "../SubscriptionPaged/ClearPayment";
import MemberUtilization from "../SubscriptionPaged/MemberUtilization";
import RenewSubscription from "../SubscriptionPaged/RenewSubscription";
import DependantsList from "../SubscriptionPaged/DependantsList";
import { color } from "@mui/system";


const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      marginLeft: '4px',
      marginTop: '30px',
      
    },
    name: {
      textTransform: "capitalize",
    },
    formField: {
      marginLeft: '4px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        width: '20%',
        alignContent: 'center ',
      },
  },
  searchBox: {
    padding: theme.spacing(2, 0),
    width: "99%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

colHeader: {
  "&:hover": {
    color: "red"
  }
},


menuItem: {
  minWidth: 200,
  margin: theme.spacing(1, 2),
  backgroundColor: theme.palette.grey[100],
  borderRadius: '3px',
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
  },
},
menuItemNoIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
menuItemWithIcon: {
  display: 'grid',
  gridTemplateColumns: '50px 1fr',
},

icon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
label: {

  fontSize: '0.95rem',
  backgroundColor: "#01579b",
  color:'white',
  display:'inline-block',
  '&:hover': {
    color: 'red',
    backgroundColor: theme.palette.grey[700],
  },
  
},



unstyledButton: {
  textTransform: 'none',
  background: 'none',
  '&:hover': {
    background: 'none',
  },
},




}));


type Props = {
  roleName?: string;
  roleId?: string;
  userTypeCode?: string;
};
const SubscriptionPagedList: React.FC<Props> = ({
  userTypeCode
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch]=useState<string>('all');
  const [query, setQuery] = useState("");
  const [user, setUser] = useState<User>();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = React.useState<string>('');
  const [memberNumber,setMemberNumber]= useState<string>('');
  const [phoneNumber,setPhoneNumber]= useState<string>('');
  const [fullName,setFullName]= useState<string>('');
  const [fromDate,setFromDate]= useState<string>('');
  const [toDate,setToDate]= useState<string>('');
  const [inputValue, setInputValue] = React.useState('');
  const [openModalForm,setOpenModalForm]= useState(false);
  const [opePay, setOpenPay]=useState(false);
  const [add, setAdd]=useState(false);
  const [openupdate, setOpenUpdate]=useState(false);
  const [opeEdit, setOpenEdit]=useState(false);
  const [openRenewSubscription, setOpenRenewSubscription]=useState(false);
  const [showAddUserModal, setShowAddToRoleModal] = useState(false);
  const [serviceType, setServiceType] = useState<any>();
  const subset="Patients";
  const [selectedRow, setSelectedRow] = useState(null);
  const { selectedUser } = useAppSelector((state) => state.login);
 
  const [openDependantModalForm,setOpenDependantModalForm]= useState(false);


     // useFetch(`/${subset}`, `fetchBreeds-${subset}`);
      const {
        isLoading,
        isRefreshing,
        isSuccess,
        isError,
        data,
        refetch,
        error
       
      } = useFetchSubscribers(page,rowsPerPage,search);


    const[updatedData, setUpdatedData] = useState([]);
    let optionsData;
   
    optionsData=data?.data || data ;
    console.log ("data" ,data);
    console.log("optionData",optionsData);
   // optionsData=data.filter((data:any) => data.typeOfService?.includes(value));

//const optionsData = data?.data || data || [] ;
  const singleUser = useFetch(
    `/auth/get-single-user/?usercode=${user?.userCode}`,
    ["user", `${user?.userCode}`],
    { enabled: !!user?.userCode }
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


  const handleEditRow = (row: any) => {
    setUser(row);
    setEditing(true);
    setOpen(true);
  };

  const actions: DataTableAction[] = [
    {
      label: "Update Location",

           onClick: (row: any) => {
        handleEditRow(row);
      },
      permission: ['SuperAdmin'].includes(
        selectedUser?.role?.toUpperCase(),
      )
        ?  `(<>icon: () => <CreateOutlined /></>)`
        : 'ACTION-NOT-ALLOWED',
    },
  ];

  const columns: DataTableColumn[] = [
    
    {
    id: "memberNumber", 
    label: "Member Number",
    
   },
   
    {
      id: "fullName",
      label: "Client Name",
      minWidth: 150,
      format: (value: any,row:any) => (
        <TableLink path={`/subscriptiondetails/${row.memberNumber}`}>
          {row.fullName}
        </TableLink>
      ),
    },
    {
      id: "packageDescription",
      label: "Package Name",
      minWidth: 150,
    },  
    
    {
      id: "patient_id",
      label: "Family",
      minWidth: 50,
      format: (value: any, row: any) => (
      row.refferedBy ==='Dependant' ? (
        <Button disabled={true} >
           Dependant
         </Button>
      ):(
        <Button disabled={row.packagePriceName==='M + 0' ? true : false} onClick={(e:any)=>handleOpenDependantsForm(row)} >
         {row.packagePriceName} 
     </Button>
      )     
      )
    },
    {
    
      id: "phoneNumber",
      label: "Phone No",
      minWidth: 150,
    },   
    {
      label: "Id Number",
      id: "idNumber",
      
    }, 
  
    {
      id: "amountPaid",
      label: "Amount Paid",
      format: (value:any,row: any) => row.amountPaid || 0, // Display 0 if amountPaid is null/undefined
    },
    
    {
    label:"Pharmacy Benefits",
      id: "pharmacy",
      format: (value:any,row: any) => row.pharmacy || 0, // Display 0 if amountPaid is null/undefined
    },

    {
      id: "patient_id",
      label: "Total Benefits",
      minWidth: 50,
      format: (value: any, row: any) => (
        <Button onClick={(e:any)=>handleOpenSubscriptionForm(row)} variant="outlined">
                <Icon icon={listOutline} />
                {/* {row.totalBenefits} */}
         </Button>
         )
    },
  
    {
      label:"Agent Name",
      id: "agentName",
     
    },
  
    {
      label:"Date Created",
      id: "created",
      render:  (value: any)=>moment(value.created)?.format("DD-MM-YYYY")
      
    },
    
  ];

const rows: DataTableRow[] = isSuccess
? userTypeCode
  ?updatedData
  : updatedData
: [];


const [searched, setSearched] = useState<string>("");

useEffect(()=>{
  (value?.length <=0 || value ==null)? 
    setUpdatedData(data?.data || data || [])
    : setUpdatedData(data.filter((data:any) => data.typeOfService.includes(value)))

},[value || query || []])


const handleDateFilter = (term: any, rowData:any) => {
  const today = Date.now();
  return new Date(today) < new Date(rowData.created)
    ? true
    : false;
};
const[permission,setPermission]=useState<any[]>(
  [sessionStorage.getItem('role')]
)


const handlePayment = (row: any) => {

  setOpenPay(true);
};

const handleOpenSubscriptionForm = (row:any) => {
  setMemberNumber(row.memberNumber);
  setFromDate(row.dateRegistered);
  setToDate(row.expiryDate);
   setOpenModalForm(true);
};

const handleCloseSubscriptionForm = () => {
  setOpenModalForm(false);
};
const handleOpenDependantsForm = (row:any) => {
  setMemberNumber(row.memberNumber);
  setOpenDependantModalForm(true);
};
const handleCloseDependantsForm = () => {
  setOpenDependantModalForm(false);
};
const handleRenewSubscription =(row: any)=>{
  setOpenRenewSubscription(true);
}

  function handleOpen(event: any, data: object | object[]): void {
    throw new Error("Function not implemented.");
  }

  return (


    <PageContent>
     



      <div className={classes.root}>
        { "Health Benefit Management Platform"}

        {isLoading ? <LoadingSpinner /> : <></>}
        {/* {isFetching ? <RefreshingCard message="Refreshing Clients" /> : <></>} */}
        {/* <div className={classes.formField}>
          <SelectTypeOfService
          label="Type of Service"
          setInputValue={setInputValue}
          setValue={setValue}
          inputValue={inputValue}
          value={value}
          serviceList={serviceList}
          />
       
      </div> */}
      {isSuccess ? (
        <>
          {/* <TableHeader
            onClickAddNew={handleClickNewBtn}
            onSearch={setQuery}
            query={query}
            addNewLabel={roleName ? "Add user" : "create Client"}
            addNewPermission=""
          /> */}
      <Box className={classes.searchBox}>
          <Box display="flex" alignItems="center">
          <InlineSearchBar 
          onSearch={setQuery}
           query={query} 
           setUpdatedData={setUpdatedData}
            setSearched={setSearched}
             updatedData={updatedData}
              searched={searched} />
          </Box>
          </Box>
          <Table
            columns={columns}
            rows={rows?.map((r, index) => ({ ...r, number: index + 1 }))}
            actions={actions}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            totalRecords={data?.totalRecords}
            rowsPerPage={rowsPerPage}
            pageCount={data?.totalPages}
            rowColor={''}
            
           
          />

            
        </>
        
          

      ) : ('<></>')}
      </div>
      <CreateUser
        queryString="clients"
        open={false}
        setOpen={setOpen}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
        <ClearPayments
        queryString="clients"
        open={opePay}
        setOpen={setOpenPay}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
        <RenewSubscription
        queryString="clients"
        open={openRenewSubscription}
        setOpen={setOpenRenewSubscription}
        editing={editing}
        setEditing={setEditing}
        setEditLoading={singleUser.isLoading}
        setEditError={singleUser.isError}
      />
      <Menu
        className={classes.root}
        id="fade-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        // onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* {getAllowedActions().map((b) => ( */}
          <>
            <MenuItem onClick={handlePayment}
              classes={{ root: classes.menuItem }}
              className={
                // b.icon ? classes.menuItemWithIcon : 
                classes.menuItemNoIcon
              }
              key="Menu-Key"
            >
            <span className={classes.label}>Complete Payment</span>
            </MenuItem>
            <MenuItem onClick={handleRenewSubscription}
              classes={{ root: classes.menuItem }}
              className={
                classes.menuItemWithIcon 
                //classes.menuItemNoIcon
              }
              key="Renew"
            >
            <span className={classes.label}>Renew</span>
            </MenuItem>
            </>
        {/* ))} */}
      </Menu>

      <Dialog fullWidth maxWidth="lg" open={openModalForm} onClose={handleCloseSubscriptionForm}>
        <DialogTitle>Member Utilization</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <MemberUtilization
          onCancel={handleCloseSubscriptionForm}
          setOpenModalForm={setOpenModalForm}
          MemberNumber={memberNumber}
          FromDate={fromDate}
          ToDate={toDate}
        />
      </Grid>
    </Grid>
    
      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openDependantModalForm} onClose={handleCloseDependantsForm}>
        <DialogTitle>Dependants List</DialogTitle>
        <Grid container spacing={5}>
      <Grid item xs={12} md={12}>
      <DependantsList
          onCancel={handleCloseDependantsForm}
          setOpenModalForm={setOpenDependantModalForm}
          MemberNumber={memberNumber}
        />
      </Grid>
    </Grid>
    
      </Dialog> 
    </PageContent>
  );
};

SubscriptionPagedList.defaultProps = {
  roleName: undefined,
  roleId: undefined,
  userTypeCode: undefined,
};

export default SubscriptionPagedList;
